@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700,900);
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0084ff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #005cb3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #a95ec7;
  --pink: #f77dc2;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #2cddbe;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0084ff;
  --secondary: #a8adb9;
  --success: #94df4a;
  --info: #86d4f5;
  --warning: #f3d768;
  --danger: #fa625e;
  --light: #f3f8fa;
  --dark: #2c2d3a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #f77dc2;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8ddff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abfff; }

.table-hover .table-primary:hover {
  background-color: #9fd1ff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fd1ff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e7e8eb; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #d2d4db; }

.table-hover .table-secondary:hover {
  background-color: #d9dbdf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d9dbdf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e1f6cc; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c7eea1; }

.table-hover .table-success:hover {
  background-color: #d4f2b6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d4f2b6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #ddf3fc; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c0e9fa; }

.table-hover .table-info:hover {
  background-color: #c6ebfa; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c6ebfa; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf4d5; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f9eab0; }

.table-hover .table-warning:hover {
  background-color: #faeebd; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #faeebd; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fed3d2; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fcadab; }

.table-hover .table-danger:hover {
  background-color: #fdbbb9; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fdbbb9; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f9fbfc; }

.table-hover .table-light:hover {
  background-color: #e9f0f8; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e9f0f8; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c4c8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #919299; }

.table-hover .table-dark:hover {
  background-color: #b7b7bc; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b7b7bc; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80c2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #94df4a; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(148, 223, 74, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #94df4a;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2394df4a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #94df4a;
    box-shadow: 0 0 0 0.2rem rgba(148, 223, 74, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #94df4a;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2394df4a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #94df4a;
    box-shadow: 0 0 0 0.2rem rgba(148, 223, 74, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #94df4a; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #94df4a; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #94df4a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #aee775;
  background-color: #aee775; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(148, 223, 74, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #94df4a; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #94df4a; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #94df4a;
  box-shadow: 0 0 0 0.2rem rgba(148, 223, 74, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fa625e; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(250, 98, 94, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #fa625e;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa625e' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa625e' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #fa625e;
    box-shadow: 0 0 0 0.2rem rgba(250, 98, 94, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #fa625e;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa625e' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa625e' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #fa625e;
    box-shadow: 0 0 0 0.2rem rgba(250, 98, 94, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fa625e; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #fa625e; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #fa625e; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fc928f;
  background-color: #fc928f; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(250, 98, 94, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fa625e; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fa625e; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #fa625e;
  box-shadow: 0 0 0 0.2rem rgba(250, 98, 94, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0084ff;
  border-color: #0084ff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0070d9;
    border-color: #006acc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 150, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0084ff;
    border-color: #0084ff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #006acc;
    border-color: #0063bf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 150, 255, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #a8adb9;
  border-color: #a8adb9; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #9399a8;
    border-color: #8c92a2; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(148, 153, 163, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #a8adb9;
    border-color: #a8adb9; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #8c92a2;
    border-color: #858c9d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 153, 163, 0.5); }

.btn-success {
  color: #212529;
  background-color: #94df4a;
  border-color: #94df4a; }
  .btn-success:hover {
    color: #212529;
    background-color: #81d929;
    border-color: #7ad125; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(131, 195, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #94df4a;
    border-color: #94df4a; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #212529;
    background-color: #7ad125;
    border-color: #74c623; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(131, 195, 69, 0.5); }

.btn-info {
  color: #212529;
  background-color: #86d4f5;
  border-color: #86d4f5; }
  .btn-info:hover {
    color: #212529;
    background-color: #63c7f2;
    border-color: #57c3f1; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 186, 214, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #86d4f5;
    border-color: #86d4f5; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #212529;
    background-color: #57c3f1;
    border-color: #4bbff0; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 186, 214, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f3d768;
  border-color: #f3d768; }
  .btn-warning:hover {
    color: #212529;
    background-color: #f0ce45;
    border-color: #efca39; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 188, 95, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f3d768;
    border-color: #f3d768; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #efca39;
    border-color: #eec72d; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 188, 95, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #fa625e;
  border-color: #fa625e; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f93e39;
    border-color: #f8322d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(251, 122, 118, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #fa625e;
    border-color: #fa625e; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f8322d;
    border-color: #f82620; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(251, 122, 118, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f3f8fa;
  border-color: #f3f8fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #d8e8ef;
    border-color: #cfe3eb; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 216, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f3f8fa;
    border-color: #f3f8fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #cfe3eb;
    border-color: #c6dee7; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 216, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #2c2d3a;
  border-color: #2c2d3a; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1c1c24;
    border-color: #16171d; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 77, 88, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2c2d3a;
    border-color: #2c2d3a; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #16171d;
    border-color: #111116; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 77, 88, 0.5); }

.btn-outline-primary {
  color: #0084ff;
  border-color: #0084ff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0084ff;
    border-color: #0084ff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0084ff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0084ff;
    border-color: #0084ff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.5); }

.btn-outline-secondary {
  color: #a8adb9;
  border-color: #a8adb9; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #a8adb9;
    border-color: #a8adb9; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 173, 185, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #a8adb9;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #a8adb9;
    border-color: #a8adb9; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 173, 185, 0.5); }

.btn-outline-success {
  color: #94df4a;
  border-color: #94df4a; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #94df4a;
    border-color: #94df4a; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(148, 223, 74, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #94df4a;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #94df4a;
    border-color: #94df4a; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 223, 74, 0.5); }

.btn-outline-info {
  color: #86d4f5;
  border-color: #86d4f5; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #86d4f5;
    border-color: #86d4f5; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 212, 245, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #86d4f5;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #86d4f5;
    border-color: #86d4f5; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(134, 212, 245, 0.5); }

.btn-outline-warning {
  color: #f3d768;
  border-color: #f3d768; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f3d768;
    border-color: #f3d768; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 215, 104, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f3d768;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f3d768;
    border-color: #f3d768; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 215, 104, 0.5); }

.btn-outline-danger {
  color: #fa625e;
  border-color: #fa625e; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #fa625e;
    border-color: #fa625e; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 98, 94, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #fa625e;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #fa625e;
    border-color: #fa625e; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 98, 94, 0.5); }

.btn-outline-light {
  color: #f3f8fa;
  border-color: #f3f8fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f3f8fa;
    border-color: #f3f8fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 248, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f3f8fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f3f8fa;
    border-color: #f3f8fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 248, 250, 0.5); }

.btn-outline-dark {
  color: #2c2d3a;
  border-color: #2c2d3a; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #2c2d3a;
    border-color: #2c2d3a; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 45, 58, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2c2d3a;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #2c2d3a;
    border-color: #2c2d3a; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 45, 58, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0084ff;
  text-decoration: none; }
  .btn-link:hover {
    color: #005cb3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0084ff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0084ff;
    background-color: #0084ff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80c2ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3daff;
    border-color: #b3daff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0084ff;
  background-color: #0084ff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 132, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 132, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 132, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 132, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80c2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80c2ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0084ff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3daff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0084ff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3daff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0084ff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3daff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0084ff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0084ff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #005cb3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0084ff;
  border-color: #0084ff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #0084ff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #006acc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 132, 255, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #a8adb9; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #8c92a2; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(168, 173, 185, 0.5); }

.badge-success {
  color: #212529;
  background-color: #94df4a; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #7ad125; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(148, 223, 74, 0.5); }

.badge-info {
  color: #212529;
  background-color: #86d4f5; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #57c3f1; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(134, 212, 245, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #f3d768; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #efca39; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 215, 104, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #fa625e; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #f8322d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 98, 94, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f3f8fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #cfe3eb; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 248, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #2c2d3a; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #16171d; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 45, 58, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004585;
  background-color: #cce6ff;
  border-color: #b8ddff; }
  .alert-primary hr {
    border-top-color: #9fd1ff; }
  .alert-primary .alert-link {
    color: #002b52; }

.alert-secondary {
  color: #575a60;
  background-color: #eeeff1;
  border-color: #e7e8eb; }
  .alert-secondary hr {
    border-top-color: #d9dbdf; }
  .alert-secondary .alert-link {
    color: #3f4145; }

.alert-success {
  color: #4d7426;
  background-color: #eaf9db;
  border-color: #e1f6cc; }
  .alert-success hr {
    border-top-color: #d4f2b6; }
  .alert-success .alert-link {
    color: #344e19; }

.alert-info {
  color: #466e7f;
  background-color: #e7f6fd;
  border-color: #ddf3fc; }
  .alert-info hr {
    border-top-color: #c6ebfa; }
  .alert-info .alert-link {
    color: #34525e; }

.alert-warning {
  color: #7e7036;
  background-color: #fdf7e1;
  border-color: #fcf4d5; }
  .alert-warning hr {
    border-top-color: #faeebd; }
  .alert-warning .alert-link {
    color: #5a5027; }

.alert-danger {
  color: #823331;
  background-color: #fee0df;
  border-color: #fed3d2; }
  .alert-danger hr {
    border-top-color: #fdbbb9; }
  .alert-danger .alert-link {
    color: #5d2423; }

.alert-light {
  color: #7e8182;
  background-color: #fdfefe;
  border-color: #fcfdfe; }
  .alert-light hr {
    border-top-color: #e9f0f8; }
  .alert-light .alert-link {
    color: #656768; }

.alert-dark {
  color: #17171e;
  background-color: #d5d5d8;
  border-color: #c4c4c8; }
  .alert-dark hr {
    border-top-color: #b7b7bc; }
  .alert-dark .alert-link {
    color: #010101; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0084ff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0084ff;
    border-color: #0084ff; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004585;
  background-color: #b8ddff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004585;
    background-color: #9fd1ff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004585;
    border-color: #004585; }

.list-group-item-secondary {
  color: #575a60;
  background-color: #e7e8eb; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #575a60;
    background-color: #d9dbdf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #575a60;
    border-color: #575a60; }

.list-group-item-success {
  color: #4d7426;
  background-color: #e1f6cc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #4d7426;
    background-color: #d4f2b6; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #4d7426;
    border-color: #4d7426; }

.list-group-item-info {
  color: #466e7f;
  background-color: #ddf3fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #466e7f;
    background-color: #c6ebfa; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #466e7f;
    border-color: #466e7f; }

.list-group-item-warning {
  color: #7e7036;
  background-color: #fcf4d5; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7e7036;
    background-color: #faeebd; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7e7036;
    border-color: #7e7036; }

.list-group-item-danger {
  color: #823331;
  background-color: #fed3d2; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #823331;
    background-color: #fdbbb9; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #823331;
    border-color: #823331; }

.list-group-item-light {
  color: #7e8182;
  background-color: #fcfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7e8182;
    background-color: #e9f0f8; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7e8182;
    border-color: #7e8182; }

.list-group-item-dark {
  color: #17171e;
  background-color: #c4c4c8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #17171e;
    background-color: #b7b7bc; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #17171e;
    border-color: #17171e; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0084ff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #006acc !important; }

.bg-secondary {
  background-color: #a8adb9 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #8c92a2 !important; }

.bg-success {
  background-color: #94df4a !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #7ad125 !important; }

.bg-info {
  background-color: #86d4f5 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #57c3f1 !important; }

.bg-warning {
  background-color: #f3d768 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #efca39 !important; }

.bg-danger {
  background-color: #fa625e !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f8322d !important; }

.bg-light {
  background-color: #f3f8fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cfe3eb !important; }

.bg-dark {
  background-color: #2c2d3a !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #16171d !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0084ff !important; }

.border-secondary {
  border-color: #a8adb9 !important; }

.border-success {
  border-color: #94df4a !important; }

.border-info {
  border-color: #86d4f5 !important; }

.border-warning {
  border-color: #f3d768 !important; }

.border-danger {
  border-color: #fa625e !important; }

.border-light {
  border-color: #f3f8fa !important; }

.border-dark {
  border-color: #2c2d3a !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0084ff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #005cb3 !important; }

.text-secondary {
  color: #a8adb9 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #7e8597 !important; }

.text-success {
  color: #94df4a !important; }

a.text-success:hover, a.text-success:focus {
  color: #6ebb21 !important; }

.text-info {
  color: #86d4f5 !important; }

a.text-info:hover, a.text-info:focus {
  color: #3fbbef !important; }

.text-warning {
  color: #f3d768 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #edc421 !important; }

.text-danger {
  color: #fa625e !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #f81a14 !important; }

.text-light {
  color: #f3f8fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #bdd9e4 !important; }

.text-dark {
  color: #2c2d3a !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0b0b0f !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*
Basic of template
*/
html {
  height: 100%; }

body {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 1800px;
  margin-left: 0;
  margin-right: auto; }

body {
  color: rgba(24, 35, 89, 0.85);
  font-size: .875rem;
  letter-spacing: 0;
  line-height: 1.8;
  background-color: #f2f6f8;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0; }
  a:hover {
    color: #0084ff;
    text-decoration: none;
    outline: 0 !important; }

a.text-muted:hover {
  opacity: 1 !important; }

button, a {
  transition: all .2s;
  -webkit-transition: all .2s; }
  button:focus, button:hover, a:focus, a:hover {
    text-decoration: none !important;
    outline: 0 !important; }

* > .disabled, * > [disabled] {
  opacity: .5;
  pointer-events: none; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: inherit; }

.small, small {
  font-size: 85%; }

hr {
  border-color: #e1eaea; }

pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0; }

.text-muted {
  color: inherit !important;
  opacity: 0.6 !important; }

.no-padding {
  padding: 0px !important; }

.no-margin {
  margin: 0px !important; }

.overflow-hidden {
  overflow: hidden !important; }

hr, .img-thumbnail {
  border-color: #e1eaea; }

.mark, mark {
  padding: .2em .5rem;
  background-color: #e5f2ff; }

/**Spaces**/
.pl-15 {
  padding-left: 15px !important; }

.pl-20 {
  padding-left: 15px !important; }

.pl-25 {
  padding-left: 15px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-20 {
  padding-right: 15px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pt-0 {
  padding-top: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-60 {
  margin-top: 60px !important; }

.font300 {
  font-weight: 300 !important; }

.font400 {
  font-weight: 400 !important; }

.font500 {
  font-weight: 600 !important; }

.font600 {
  font-weight: 600 !important; }

.font700 {
  font-weight: 700 !important; }

.fs10 {
  font-size: 10px !important; }

.fs12 {
  font-size: 12px !important; }

.fs13 {
  font-size: 13px !important; }

.fs14 {
  font-size: 14px !important; }

.fs15 {
  font-size: 15px !important; }

.fs16 {
  font-size: 16px !important; }

.fs17 {
  font-size: 17px !important; }

.fs18 {
  font-size: 18px !important; }

.fs19 {
  font-size: 19px !important; }

.fs20 {
  font-size: 20px !important; }

.fs21 {
  font-size: 21px !important; }

.fs22 {
  font-size: 22px !important; }

.fs23 {
  font-size: 23px !important; }

.fs24 {
  font-size: 24px !important; }

.fs-1x {
  font-size: 1rem !important;
  line-height: 1.25; }

.fs-2x {
  font-size: 2rem !important;
  line-height: 1.25; }

.fs-3x {
  font-size: 3rem !important;
  line-height: 1.25; }

.fs-4x {
  font-size: 4rem !important;
  line-height: 1.25; }

.fs-5x {
  font-size: 5rem !important;
  line-height: 1.25; }

.v-m {
  vertical-align: middle; }

.lineH-1 {
  line-height: 1 !important; }

.pRelative {
  position: relative !important; }

/**background and badges colors**/
.badge {
  letter-spacing: 0px;
  padding: 0px;
  padding-top: 0px;
  width: 21px;
  height: 21px;
  line-height: 21px;
  border-radius: 100px;
  display: inline-block !important;
  text-align: center;
  font-size: .7rem;
  font-weight: 500;
  position: relative; }
  .badge.badge-text {
    width: auto !important;
    height: auto !important;
    line-height: 1 !important;
    padding: .25rem .35rem;
    border-radius: 4px;
    font-size: .65rem;
    position: relative; }
  .badge:empty {
    display: inline-flex !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px !important;
    padding: 0px; }
  .badge.anibadge:before, .badge.anibadge:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; }
  .badge.anibadge:after {
    z-index: 10;
    border: 5px solid rgba(242, 246, 248, 0.4);
    -webkit-animation: not-blink 2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: not-blink 2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
  .badge.anibadge.badge-danger:after {
    border-color: rgba(250, 98, 94, 0.75); }
  .badge.anibadge.badge-success:after {
    border-color: rgba(148, 223, 74, 0.75); }
  .badge.anibadge.badge-warning:after {
    border-color: rgba(243, 215, 104, 0.75); }
  .badge.anibadge.badge-info:after {
    border-color: rgba(134, 212, 245, 0.75); }
  .badge.anibadge.badge-teal:after {
    border-color: rgba(44, 221, 190, 0.75); }
  .badge.anibadge.badge-purple:after {
    border-color: rgba(169, 94, 199, 0.75); }
  .badge.anibadge.badge-primary:after {
    border-color: rgba(0, 132, 255, 0.75); }
  .badge.anibadge.badge-dark:after {
    border-color: rgba(44, 45, 58, 0.75); }
  .badge.anibadge.badge-pink:after {
    border-color: rgba(247, 125, 194, 0.75); }
  .badge.anibadge.badge-light:after {
    border-color: #f3f8fa; }

.badge.badge.badge-text.anibadge:before, .badge.badge.badge-text.anibadge:after {
  border-radius: 2px; }

.bg-white, .badge-white {
  background-color: #fff !important; }

.bg-light, .badge-light {
  background-color: #f3f8fa !important; }

.bg-secondary, .badge-secondary {
  background-color: #a8adb9 !important; }

.bg-dark, .badge-dark {
  background-color: #2c2d3a !important;
  color: #868aa8;
  border-color: rgba(134, 138, 168, 0.2) !important; }
  .bg-dark .b-b, .bg-dark .b-t, .bg-dark .b-r, .bg-dark .b-l, .badge-dark .b-b, .badge-dark .b-t, .badge-dark .b-r, .badge-dark .b-l {
    border-color: rgba(225, 234, 234, 0.2) !important; }
  .bg-dark .text-dark, .badge-dark .text-dark {
    color: #868aa8 !important; }

.bg-primary, .badge-primary {
  background-color: #0084ff !important;
  color: #e5f2ff; }
  .bg-primary .b-b, .bg-primary .b-t, .bg-primary .b-r, .bg-primary .b-l, .badge-primary .b-b, .badge-primary .b-t, .badge-primary .b-r, .badge-primary .b-l {
    border-color: rgba(229, 242, 255, 0.2) !important; }

.bg-danger, .badge-danger {
  background-color: #fa625e !important;
  color: #fff;
  color: #fee5e4; }
  .bg-danger .b-b, .bg-danger .b-t, .bg-danger .b-r, .bg-danger .b-l, .badge-danger .b-b, .badge-danger .b-t, .badge-danger .b-r, .badge-danger .b-l {
    border-color: rgba(254, 229, 228, 0.2) !important; }

.bg-success, .badge-success {
  background-color: #94df4a !important;
  color: #e5fcd0; }
  .bg-success .b-b, .bg-success .b-t, .bg-success .b-r, .bg-success .b-l, .badge-success .b-b, .badge-success .b-t, .badge-success .b-r, .badge-success .b-l {
    border-color: rgba(229, 252, 208, 0.2) !important; }

.bg-warning, .badge-warning {
  background-color: #f3d768 !important;
  color: #fef8e2; }
  .bg-warning .b-b, .bg-warning .b-t, .bg-warning .b-r, .bg-warning .b-l, .badge-warning .b-b, .badge-warning .b-t, .badge-warning .b-r, .badge-warning .b-l {
    border-color: rgba(254, 248, 226, 0.2) !important; }

.bg-info, .badge-info {
  background-color: #86d4f5 !important;
  color: #e7f7fe; }
  .bg-info .b-b, .bg-info .b-t, .bg-info .b-r, .bg-info .b-l, .badge-info .b-b, .badge-info .b-t, .badge-info .b-r, .badge-info .b-l {
    border-color: rgba(231, 247, 254, 0.2) !important; }

.bg-purple, .badge-purple {
  background-color: #a95ec7 !important;
  color: #fff; }

.bg-teal, .badge-teal {
  background-color: #2cddbe !important;
  color: #d9fff8;
  border-color: rgba(32, 199, 170, 0.2); }

.bg-pink, .badge-pink {
  background-color: #f77dc2 !important;
  color: #fde6f3;
  border-color: rgba(218, 85, 160, 0.2); }

.bg-faded, .badge-faded {
  background-color: #f2f6f8 !important; }

.bg-trans {
  background-color: transparent !important; }

.bg-primary-active {
  background-color: #1072ce !important; }

.bg-light-active {
  background-color: #f3f6fc !important; }

.bg-success-active {
  background-color: #7dc835 !important; }

.bg-info-active {
  background-color: #69c1e6 !important; }

.bg-warning-active {
  background-color: #dfc14a !important; }

.bg-dark-active {
  background-color: #262734 !important;
  color: #868aa8;
  border-color: rgba(255, 255, 255, 0.2) !important; }
  .bg-dark-active .b-b, .bg-dark-active .b-t, .bg-dark-active .b-r, .bg-dark-active .b-l {
    border-color: rgba(134, 138, 168, 0.2) !important; }
  .bg-dark-active .text-dark {
    color: #bcbdc5 !important; }

.bg-secondary-active {
  background-color: #828897 !important; }

.bg-teal-active {
  background-color: #20c7aa !important; }

.bg-danger-active {
  background-color: #e3423d !important; }

.bg-purple-active {
  background-color: #8f3faf !important; }

.bg-pink-active {
  background-color: #da55a0 !important; }

.bg-primary-light {
  background-color: #e5f2ff !important; }

.bg-success-light {
  background-color: #e5fcd0 !important; }

.bg-info-light {
  background-color: #e7f7fe !important; }

.bg-danger-light {
  background-color: #fee5e4 !important; }

.bg-warning-light {
  background-color: #fef8e2 !important; }

.bg-teal-light {
  background-color: #d9fff8 !important; }

.bg-purple-light {
  background-color: #f4daff !important; }

.bg-pink-light {
  background-color: #fde6f3 !important; }

.bg-dark-light {
  background-color: #bcbdc5 !important; }

.bg-secondary-light {
  background-color: #dfe0e3 !important; }

.bg-dark-light {
  background-color: #bcbdc5 !important; }

.bg-text-body {
  background-color: #9ba4ae; }

.bg-text-dark {
  background-color: #182359; }

.bg-tem-border {
  background-color: #e1eaea; }

/**text colors**/
.text-warning-active {
  color: #dfc14a !important; }

.text-success-active {
  color: #7dc835 !important; }

.text-primary-active {
  color: #1072ce !important; }

.text-teal-active {
  color: #20c7aa !important; }

.text-purple-active {
  color: #8f3faf !important; }

.text-dark-active {
  color: #262734 !important; }

.text-info-active {
  color: #69c1e6 !important; }

.text-danger-active {
  color: #e3423d !important; }

.text-pink-active {
  color: #da55a0 !important; }

.text-primary-light {
  color: #e5f2ff !important; }

.text-success-light {
  color: #e5fcd0 !important; }

.text-danger-light {
  color: #fee5e4 !important; }

.text-info-light {
  color: #e7f7fe !important; }

.text-teal-light {
  color: #d9fff8 !important; }

.text-warning-light {
  color: #fef8e2 !important; }

.text-purple-light {
  color: #f4daff !important; }

.text-pink-light {
  color: #fde6f3 !important; }

.text-dark-light {
  color: #bcbdc5 !important; }

.text-light {
  color: #868aa8 !important; }

.text-dark {
  color: #182359 !important; }

.text-faded {
  color: #f2f6f8 !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0084ff !important; }

.text-danger {
  color: #fa625e !important; }

.text-success {
  color: #94df4a !important; }

.text-warning {
  color: #f3d768 !important; }

.text-info {
  color: #86d4f5 !important; }

.text-teal {
  color: #2cddbe !important; }

.text-secondary {
  color: #a8adb9 !important; }

.text-purple {
  color: #a95ec7 !important; }

.text-white {
  color: #fff !important; }

.text-pink {
  color: #f77dc2 !important; }

.text-title {
  color: #182359 !important; }

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #1072ce !important; }

a.bg-teal:hover, a.bg-teal:focus {
  background-color: #20c7aa !important; }

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e3423d !important; }

a.bg-success:hover, a.bg-success:focus {
  background-color: #7dc835 !important; }

a.bg-purple:hover, a.bg-purple:focus {
  background-color: #8f3faf !important; }

a.bg-info:hover, a.bg-info:focus {
  background-color: #69c1e6 !important; }

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #dfc14a !important; }

a.bg-dark:hover, a.bg-dark:focus {
  background-color: #262734 !important; }

a.bg-pink:hover, a.bg-pink:focus {
  background-color: #da55a0 !important; }

a.text-primary:hover {
  color: #1072ce !important; }

a.text-info:hover {
  color: #69c1e6 !important; }

a.text-success:hover {
  color: #7dc835 !important; }

a.text-warning:hover {
  color: #dfc14a !important; }

a.text-danger:hover {
  color: #e3423d !important; }

a.text-dark:hover {
  color: #262734 !important; }

a.text-teal:hover {
  color: #20c7aa !important; }

a.text-purple:hover {
  color: #8f3faf !important; }

a.text-pink:hover {
  color: #da55a0 !important; }

.bg-gradient-primary {
  background-color: #0084ff;
  background: -webkit-gradient(linear, right top, left top, from(#0084ff), to(#1072ce));
  background: linear-gradient(-90deg, #0084ff, #1072ce);
  background: -webkit-linear-gradient(-90deg, #0084ff, #1072ce); }

.bg-gradient-light {
  background: #fff;
  background: -webkit-gradient(linear, right top, left top, from(#fee5e4), to(#e5f2ff));
  background: linear-gradient(-90deg, #fee5e4, #e5f2ff);
  background: -webkit-linear-gradient(-90deg, #fee5e4, #e5f2ff); }

.opacity1 {
  opacity: 200% !important; }

/**avatar & icons**/
.avatar {
  position: relative;
  line-height: 1 !important;
  white-space: nowrap;
  font-weight: normal;
  border-radius: 0px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }
  .avatar img {
    border-radius: 0px;
    width: 100%; }

.avatar24 {
  width: 24px !important;
  height: 24px !important;
  font-size: 0.75rem !important; }
  .avatar24 > * {
    font-size: 0.75rem !important; }

.avatar32 {
  width: 32px !important;
  height: 32px !important;
  font-size: 0.85rem !important; }
  .avatar32 > * {
    font-size: 15px !important; }

.avatar40 {
  width: 40px !important;
  height: 40px !important; }
  .avatar40 > * {
    font-size: 14px !important; }

.avatar50 {
  width: 50px !important;
  height: 50px !important;
  font-size: 18px !important; }
  .avatar50 > * {
    font-size: 18px !important; }

.avatar60 {
  width: 60px !important;
  height: 60px !important;
  font-size: 24px !important; }
  .avatar60 > * {
    font-size: 24px !important; }

.avatar80 {
  width: 80px !important;
  height: 80px !important;
  font-size: 36px !important; }
  .avatar80 > * {
    font-size: 36px !important; }

.avatar100 {
  width: 100px !important;
  height: 100px !important;
  font-size: 44px !important; }
  .avatar100 > * {
    font-size: 44px !important; }

.circle {
  border-radius: 1000px;
  -webkit-border-radius: 1000px; }

/**lists**/
.list {
  padding-left: 0px;
  padding-right: 0px; }
  .list .list-item {
    display: flex;
    align-items: center;
    padding: .65rem .5rem;
    position: relative;
    transition: all .3s;
    -webkit-transition: all .3s; }
    .list .list-item a.list-link {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      margin: 0px;
      z-index: 2; }
    .list .list-item .list-thumb {
      position: relative;
      line-height: 1;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }
    .list .list-item .list-body {
      flex: 1 1;
      position: relative; }
      .list .list-item .list-body > *:not(.btn) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical; }
      .list .list-item .list-body .list-title {
        font-weight: 500;
        text-transform: capitalize;
        color: inherit; }
      .list .list-item .list-body .list-content {
        line-height: 1.3;
        color: inherit;
        opacity: .6; }
    .list .list-item > * {
      margin-left: .5rem;
      margin-right: .5rem; }
    .list .list-item .list-meta {
      display: block;
      font-size: .7rem;
      line-height: 1.8;
      opacity: .47;
      color: inherit; }
    .list .list-item:last-child .list-body:after {
      display: none; }
    .list .list-item.unread {
      background-color: rgba(243, 248, 250, 0.7);
      border-bottom: 1px solid #f3f8fa;
      border-top: 1px solid #f2f6f8; }
      .list .list-item.unread .list-body .list-title {
        opacity: 1; }
      .list .list-item.unread:hover {
        background-color: #f3f6fc; }

.list-alt {
  overflow: hidden; }
  .list-alt .list-item {
    width: 100%;
    display: flex;
    align-items: stretch;
    height: 80px; }
    .list-alt .list-item .list-thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      flex-shrink: 0;
      background-color: rgba(0, 0, 0, 0.1);
      font-size: 1rem;
      height: 100%; }
    .list-alt .list-item .list-body {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-left: 1rem;
      padding-right: 1rem; }
      .list-alt .list-item .list-body .list-title {
        font-size: 1.8rem;
        line-height: 1.1; }
      .list-alt .list-item .list-body .list-content {
        opacity: .75;
        text-transform: capitalize; }

/**group-thumnails**/
.thumbnails-group {
  position: relative; }
  .thumbnails-group > .translate-thumb {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: block;
    float: left;
    transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    overflow: hidden;
    text-align: center;
    background-color: #f3f8fa;
    box-shadow: 0 0 0 2px white;
    margin-right: 2px; }
    .thumbnails-group > .translate-thumb > * {
      border-radius: 50%;
      -webkit-border-radius: 50%;
      margin-top: -1px; }
    .thumbnails-group > .translate-thumb:nth-child(2) {
      -webkit-transform: translateX(-15px);
      transform: translateX(-15px); }
    .thumbnails-group > .translate-thumb:nth-child(3) {
      -webkit-transform: translateX(-30px);
      transform: translateX(-30px); }
    .thumbnails-group > .translate-thumb:nth-child(4) {
      -webkit-transform: translateX(-45px);
      transform: translateX(-45px); }
    .thumbnails-group > .translate-thumb:nth-child(5) {
      -webkit-transform: translateX(-60px);
      transform: translateX(-60px); }
    .thumbnails-group > .translate-thumb:nth-child(6) {
      -webkit-transform: translateX(-75px);
      transform: translateX(-75px); }
  .thumbnails-group:after {
    content: "";
    display: table;
    clear: both;
    width: 100%; }
  .thumbnails-group:hover .translate-thumb:nth-child(2), .thumbnails-group:hover .translate-thumb:nth-child(3), .thumbnails-group:hover .translate-thumb:nth-child(4), .thumbnails-group:hover .translate-thumb:nth-child(5), .thumbnails-group:hover .translate-thumb:nth-child(6) {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }

.flex {
  flex: 1 1; }

.w100 {
  width: 100% !important; }
  .w100:after {
    content: "";
    display: table;
    clear: both; }

.border0 {
  border: 0px !important; }

.shadow0 {
  box-shadow: none !important; }
  .shadow0:focus {
    box-shadow: none; }

.border1 {
  border: 1px solid #e1eaea !important; }

.brd-danger {
  border-color: #fa625e !important; }

.brd-success {
  border-color: #94df4a !important; }

.brd-info {
  border-color: #86d4f5 !important; }

.brd-warning {
  border-color: #f3d768 !important; }

.brd-primary {
  border-color: #0084ff !important; }

.brd-teal {
  border-color: #2cddbe !important; }

.brd-dark {
  border-color: #2c2d3a !important; }

.brd-purple {
  border-color: #a95ec7 !important; }

.brd-pink {
  border-color: #f77dc2 !important; }

.brd-primary-active {
  border-color: rgba(16, 114, 206, 0.25) !important; }

.brd-success-active {
  border-color: rgba(125, 200, 53, 0.25) !important; }

.brd-warning-active {
  border-color: rgba(223, 193, 74, 0.25) !important; }

.brd-teal-active {
  border-color: rgba(32, 199, 170, 0.25) !important; }

.brd-info-active {
  border-color: rgba(105, 193, 230, 0.25) !important; }

.brd-purple-active {
  border-color: rgba(143, 63, 175, 0.25) !important; }

.brd-pink-active {
  border-color: rgba(218, 85, 160, 0.25) !important; }

.brd-danger-active {
  border-color: rgba(227, 66, 61, 0.25) !important; }

.brd-dark-active {
  border-color: rgba(38, 39, 52, 0.25) !important; }

.shadow-sm {
  box-shadow: 0px 1px 2px 0px rgba(44, 45, 58, 0.14); }

label {
  font-weight: 400;
  color: inherit;
  text-transform: capitalize; }

strong, b {
  font-weight: 700; }

.title-sep {
  position: relative;
  text-align: center;
  width: 100%;
  height: auto;
  text-transform: capitalize; }
  .title-sep:before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #e1eaea;
    left: 0;
    top: 50%;
    position: absolute; }
  .title-sep span {
    display: inline-block;
    padding: .35rem 1rem;
    z-index: 1;
    position: relative;
    top: -.1rem;
    font-weight: 400; }
  .title-sep.sep-light span {
    background: #f3f8fa; }
  .title-sep.sep-body span {
    background: #f2f6f8; }
  .title-sep.sep-white span {
    background: #fff; }
  .title-sep.sep-primary span {
    background: #0084ff; }
  .title-sep.sep-primary:before {
    background-color: rgba(229, 242, 255, 0.5); }
  .title-sep.sep-warning span {
    background: #f3d768; }
  .title-sep.sep-warning:before {
    background-color: rgba(254, 248, 226, 0.5); }
  .title-sep.sep-info span {
    background: #86d4f5; }
  .title-sep.sep-info:before {
    background-color: rgba(231, 247, 254, 0.5); }
  .title-sep.sep-success span {
    background: #94df4a; }
  .title-sep.sep-success:before {
    background-color: rgba(229, 252, 208, 0.5); }
  .title-sep.sep-dark span {
    background: #2c2d3a; }
  .title-sep.sep-dark:before {
    background-color: rgba(188, 189, 197, 0.5); }
  .title-sep.sep-teal span {
    background: #2cddbe; }
  .title-sep.sep-teal:before {
    background-color: rgba(217, 255, 248, 0.5); }
  .title-sep.sep-purple span {
    background: #a95ec7; }
  .title-sep.sep-purple:before {
    background-color: rgba(244, 218, 255, 0.5); }
  .title-sep.sep-pink span {
    background: #f77dc2; }
  .title-sep.sep-pink:before {
    background-color: rgba(253, 230, 243, 0.5); }
  .title-sep.sep-danger span {
    background: #fa625e; }
  .title-sep.sep-danger:before {
    background-color: rgba(254, 229, 228, 0.5); }
  .title-sep.sep-faded span {
    background: #f2f6f8; }
  .title-sep.sep-faded:before {
    background-color: #e1eaea; }
  .title-sep.text-left span {
    padding-left: 0px; }
  .title-sep.text-right span {
    padding-right: 0px; }

.fullscreen {
  height: 100%; }

.full-height {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-position: center center;
  background-attachment: scroll; }

/** responsive hidden/show elements classes**/
@media (min-width: 992px) {
  .h-lg-up {
    display: none !important; } }

@media (min-width: 768px) {
  .h-md-up {
    display: none !important; } }

@media (min-width: 576px) {
  .h-sm-up {
    display: none !important; } }

@media (max-width: 991px) {
  .h-lg-down {
    display: none !important; } }

@media (max-width: 767px) {
  .h-md-down {
    display: none !important; } }

@media (max-width: 575px) {
  .h-sm-down {
    display: none !important; } }

.radius2 {
  border-radius: 2px !important; }

.radius4 {
  border-radius: 4px !important; }

.radius6 {
  border-radius: 6px !important; }

.radius100 {
  border-radius: 100px !important; }

.radius-round {
  border-radius: 50% !important; }

.b-t {
  border-top: 1px solid #e1eaea !important; }

.b-b {
  border-bottom: 1px solid #e1eaea !important; }

.b-l {
  border-left: 1px solid #e1eaea !important; }

.b-r {
  border-right: 1px solid #e1eaea !important; }

.bd-1 {
  border: 1px solid !important; }

.bd-light {
  border-color: #e1eaea !important; }

.position-relative {
  position: relative; }

.user-states {
  position: relative;
  display: inline-block; }
  .user-states:before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 2px #fff; }
  .user-states.states-online:before {
    background-color: #94df4a; }
  .user-states.states-away:before {
    background-color: #fef8e2; }
  .user-states.states-dnd:before {
    background-color: #fa625e; }
  .user-states.states-offline:before {
    background-color: #a8adb9; }

/**Keyframe animations**/
@keyframes not-blink {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -wekbit-transform: scale(1);
    opacity: 0; }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -wekbit-transform: scale(1.5);
    opacity: .4; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -wekbit-transform: scale(2);
    opacity: 0; } }

@-webkit-keyframes not-blink {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -wekbit-transform: scale(1);
    opacity: 0; }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -wekbit-transform: scale(1.5);
    opacity: .4; }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -wekbit-transform: scale(2);
    opacity: 0; } }

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2); } }

@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2); } }

/*** demo icons**/
.demo-icon {
  display: block;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid #eee;
  transition: all .3s;
  -webkit-transition: all .3s;
  overflow: hidden;
  border-radius: 8px; }
  .demo-icon .demo-icon__preview {
    font-size: 1.5rem;
    color: rgba(16, 114, 206, 0.8); }
  .demo-icon .demo-icon__class {
    color: rgba(44, 45, 58, 0.5);
    padding: .3rem .3rem;
    border-top: 1px solid #eee;
    font-size: 12px;
    font-weight: 600; }
  .demo-icon:hover {
    background-color: #f2f6f8; }

.demo-icon-line {
  border: 1px solid #eee;
  border-radius: 8px;
  transition: all .3s;
  -webkit-transition: all .3s;
  text-align: center; }
  .demo-icon-line > span {
    display: block;
    padding: 1.5rem 1rem;
    font-size: 2.8rem;
    color: rgba(16, 114, 206, 0.9); }
    .demo-icon-line > span.mls {
      font-weight: 600;
      font-size: 12px;
      padding: .5rem 1rem;
      color: rgba(44, 45, 58, 0.5);
      border-top: 1px solid #eee; }
  .demo-icon-line:hover {
    background-color: #f2f6f8; }

.bg-pattern {
  background-image: url(/static/media/pattern-1.8b33b229.png);
  background-repeat: repeat;
  background-position: center center; }

/**demo code **/
.cteal {
  color: #2cddbe; }

.cpactive {
  color: #1072ce; }

.cinfo {
  color: #86d4f5; }

.cdanger {
  color: #fa625e; }

.list-features li {
  border-top: 1px solid #f3f8fa;
  padding: 0rem 1rem;
  height: 44px;
  line-height: 44px; }
  .list-features li > i {
    font-size: 24px !important;
    line-height: 44px;
    display: block; }

/*
Accordions
*/
.card-accordions .card, .card-accordions .card-header {
  border: 0px;
  border-radius: 5px; }

.card-accordions .card .card-body {
  border-left: 1px solid #e5f2ff;
  border-right: 1px solid #e5f2ff;
  border-bottom: 1px solid #e5f2ff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.card-accordions .card .card-header {
  position: relative;
  padding: 0px; }
  .card-accordions .card .card-header > .collapse-title {
    padding: .55rem 1.25rem;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    background-color: #0084ff;
    font-weight: 500;
    font-size: .85rem;
    color: #fff; }
    .card-accordions .card .card-header > .collapse-title.collapsed {
      border-radius: 5px;
      background-color: #f3f8fa;
      color: inherit; }

.card-accordions-flat .card, .card-accordions-flat .card .card-header {
  border-radius: 0px;
  border: 0px; }

.card-accordions-flat .card .card-body {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.card-accordions-flat .card-header {
  position: relative;
  padding: 0px; }
  .card-accordions-flat .card-header > .collapse-title {
    padding: .5rem 1.25rem;
    display: block;
    position: relative;
    background-color: #fff;
    font-weight: 500;
    font-size: .85rem;
    color: #0084ff; }
    .card-accordions-flat .card-header > .collapse-title.collapsed {
      background-color: #fff;
      color: #182359; }
      .card-accordions-flat .card-header > .collapse-title.collapsed:hover {
        color: #0084ff; }
    .card-accordions-flat .card-header > .collapse-title:hover {
      color: #0084ff; }

.collapse-title:before {
  position: absolute;
  display: inline-block;
  transition: all .2s;
  -webkit-transition: all .2s;
  right: 1.25rem;
  top: 50%;
  height: 12px;
  width: 12px;
  text-align: center;
  line-height: 12px;
  margin-top: -4px;
  font-size: 16px;
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  font-weight: 900; }

.collapse-title.collapsed:before {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); }

/*
Alert and notifications
*/
.close {
  position: absolute;
  padding: 0.65rem 1.25rem;
  text-shadow: none;
  color: inherit;
  right: 5px !important;
  top: 0px !important; }
  .close:hover {
    color: inherit;
    opacity: 1; }

.alert {
  position: relative;
  border-color: inherit; }
  .alert .alert-link {
    color: inherit !important;
    font-weight: 600;
    border-bottom: 1px solid;
    border-bottom-color: rgba(44, 45, 58, 0.1); }
  .alert.animated {
    -webkit-animation-duration: 1s !important;
    animation-duration: 1s !important;
    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important; }
  .alert a {
    color: inherit;
    font-weight: 700; }
    .alert a:hover {
      color: inherit;
      text-decoration: underline; }
  .alert.alert-primary {
    background-color: #e5f2ff;
    color: #1072ce;
    border-color: rgba(0, 132, 255, 0.15); }
    .alert.alert-primary hr {
      border-top-color: rgba(0, 132, 255, 0.15); }
  .alert.alert-warning {
    background-color: #fef8e2;
    color: #dfc14a;
    border-color: rgba(243, 215, 104, 0.15); }
    .alert.alert-warning hr {
      border-top-color: rgba(243, 215, 104, 0.15); }
  .alert.alert-success {
    background-color: #e5fcd0;
    color: #7dc835;
    border-color: rgba(148, 223, 74, 0.15); }
    .alert.alert-success hr {
      border-top-color: rgba(148, 223, 74, 0.15); }
  .alert.alert-danger {
    background-color: #fee5e4;
    color: #e3423d;
    border-color: rgba(250, 98, 94, 0.15); }
    .alert.alert-danger hr {
      border-top-color: rgba(250, 98, 94, 0.15); }
  .alert.alert-info {
    background-color: #e7f7fe;
    color: #69c1e6;
    border-color: rgba(134, 212, 245, 0.15); }
    .alert.alert-info hr {
      border-top-color: rgba(134, 212, 245, 0.15); }
  .alert.alert-teal {
    background-color: #d9fff8;
    color: #20c7aa;
    border-color: rgba(44, 221, 190, 0.15); }
    .alert.alert-teal hr {
      border-top-color: rgba(44, 221, 190, 0.15); }
  .alert.alert-secondary {
    background-color: #dfe0e3;
    color: #828897;
    border-color: rgba(168, 173, 185, 0.15); }
    .alert.alert-secondary hr {
      border-top-color: rgba(168, 173, 185, 0.15); }
  .alert.alert-light {
    background-color: #fff;
    color: #9ba4ae;
    border-color: #e1eaea; }
  .alert.alert-dark {
    background-color: #f2f6f8;
    color: #262734;
    border-color: rgba(44, 45, 58, 0.15); }
    .alert.alert-dark hr {
      border-top-color: rgba(44, 45, 58, 0.15); }
  .alert.alert-purple {
    background-color: #f4daff;
    color: #8f3faf;
    border-color: rgba(169, 94, 199, 0.15); }
    .alert.alert-purple hr {
      border-top-color: rgba(169, 94, 199, 0.15); }
  .alert.alert-pink {
    background-color: #fde6f3;
    color: #da55a0;
    border-color: rgba(247, 125, 194, 0.15); }
    .alert.alert-pink hr {
      border-top-color: rgba(247, 125, 194, 0.15); }

/**notifications - bootstrap-notify**/
.alert .progress {
  position: absolute;
  width: calc(100% - 2px);
  left: 0;
  right: 0;
  bottom: -1px;
  height: 2px;
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent; }
  .alert .progress > .progress-bar {
    background-color: #0084ff;
    border-radius: 0px;
    height: 2px;
    line-height: 2px;
    border-bottom-left-radius: .25rem;
    -webkit-border-bottom-right-radius: .25rem; }

.alert.alert-info .progress > .progress-bar {
  background-color: #86d4f5; }

.alert.alert-success .progress > .progress-bar {
  background-color: #94df4a; }

.alert.alert-warning .progress > .progress-bar {
  background-color: #f3d768; }

.alert.alert-danger .progress > .progress-bar {
  background-color: #fa625e; }

.alert.alert-light .progress > .progress-bar {
  background-color: #e1eaea; }

.alert.alert-primary .progress > .progress-bar {
  background-color: #0084ff; }

.alert.alert-teal .progress > .progress-bar {
  background-color: #2cddbe; }

.alert.alert-purple .progress > .progress-bar {
  background-color: #a95ec7; }

.alert.alert-dark .progress > .progress-bar {
  background-color: #2c2d3a; }

.alert.alert-pink .progress > .progress-bar {
  background-color: #f77dc2; }

.table-demo-ui tr, .table-demo-ui th, .table-demo-ui td, .table-demo-ui thead, .table-demo-ui tbody {
  vertical-align: middle; }

[data-notify="title"] {
  display: block;
  width: 100%;
  font-weight: 500 !important; }

[data-notify="message"] {
  display: block;
  width: 100%; }

[data-notify="icon"].alert-thumb, .alert-thumb [data-notify="icon"] {
  float: left;
  display: inline-flex !important;
  margin-right: 0px;
  text-align: center;
  width: 2.5rem;
  height: 100%;
  line-height: 1;
  position: absolute;
  left: .5rem;
  top: -1px;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden;
  font-size: 16px; }
  [data-notify="icon"].alert-thumb img, .alert-thumb [data-notify="icon"] img {
    width: 90%;
    display: block;
    height: auto;
    position: relative;
    top: 1px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    -webkit-border-radius: 50%; }

.alert-thumb [data-notify="icon"] + *, [data-notify="icon"].alert-thumb + *, .alert-thumb [data-notify="icon"] + * + *, [data-notify="icon"].alert-thumb + * + * {
  padding-left: 2rem; }

/*
Email App
*/
.table.table-mails td, .table.table-mails th {
  vertical-align: middle; }

.table.table-mails button[data-toggle="button"].btn {
  width: auto;
  min-width: 1px; }
  .table.table-mails button[data-toggle="button"].btn:focus, .table.table-mails button[data-toggle="button"].btn .focus, .table.table-mails button[data-toggle="button"].btn:active, .table.table-mails button[data-toggle="button"].btn .active {
    outline: 0 !important;
    box-shadow: none !important; }
  .table.table-mails button[data-toggle="button"].btn.active {
    outline: 0 !important; }
    .table.table-mails button[data-toggle="button"].btn.active > i:before {
      font-weight: 900; }

.table.table-mails td, .table.table-mails th {
  padding: 1rem 1rem; }

.table.table-mails tbody > tr {
  opacity: 1; }
  .table.table-mails tbody > tr:hover {
    background-color: #f3f8fa; }

.table.table-mails tr.mail-new, .table.table-mails tbody > tr:hover {
  opacity: 1; }
  .table.table-mails tr.mail-new .mail-content, .table.table-mails tr.mail-new .mail-title, .table.table-mails tr.mail-new .mail-author-title, .table.table-mails tbody > tr:hover .mail-content, .table.table-mails tbody > tr:hover .mail-title, .table.table-mails tbody > tr:hover .mail-author-title {
    opacity: 1; }

.table.table-mails tr.mail-new:hover {
  background-color: #f3f8fa; }

.table.table-mails thead tr th, .table.table-mails tbody tr td {
  border-color: #f3f8fa; }

.mail-title {
  display: block;
  font-weight: 500;
  color: #2c2d3a;
  opacity: .85;
  text-transform: capitalize;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 10px; }

.mail-author-title {
  font-size: .8rem;
  color: #0084ff;
  opacity: .75; }

.mail-content {
  color: #9ba4ae;
  opacity: .6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; }

.table.table-mails tr:first-child td, .table.table-mails tr:first-child th {
  border-top: 0px; }

/*
Layout style for app pages like chat, email etc...
*/
.sidebar {
  display: flex !important;
  position: relative;
  z-index: 500;
  overflow: hidden;
  width: 280px; }
  .sidebar.show {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.sidebar .flex-column, .sidebar-body .flex-column {
  height: 100vh;
  overflow: hidden; }

.sidebar-body-footer, .sidebar-footer {
  min-height: 3.5rem;
  line-height: 1;
  position: relative; }

.list.chat-list .chat-user {
  position: relative; }
  .list.chat-list .chat-user:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    right: 15px;
    top: 18px;
    background-color: #e1eaea;
    border-radius: 50%;
    display: block; }
  .list.chat-list .chat-user.user-online:after {
    background-color: #94df4a; }
  .list.chat-list .chat-user.user-away:after {
    background-color: #f3d768; }
  .list.chat-list .chat-user.user-dnd:after {
    background-color: #fa625e; }

.sidebar .chat-list .list-item {
  transition: all .3s;
  -webkit-transition: all .3s;
  border-left: 3px solid transparent; }
  .sidebar .chat-list .list-item:hover {
    background-color: rgba(243, 248, 250, 0.7);
    border-left-color: #0084ff; }
  .sidebar .chat-list .list-item.active {
    background-color: #f3f8fa;
    border-left-color: #0084ff; }

.sidebar .flex-column, .sidebar-body .flex-column {
  width: 100%; }

.sidebar .nav .nav-item {
  display: block;
  width: 100%; }
  .sidebar .nav .nav-item .nav-link {
    display: block;
    opacity: .75;
    font-weight: 500; }
    .sidebar .nav .nav-item .nav-link > i {
      opacity: .35; }
    .sidebar .nav .nav-item .nav-link:hover {
      box-shadow: inset 3px 0px 0px 0px #0084ff;
      opacity: 1; }
      .sidebar .nav .nav-item .nav-link:hover > i {
        opacity: 1; }
    .sidebar .nav .nav-item .nav-link.active {
      box-shadow: inset 3px 0px 0px 0px #0084ff;
      color: #0084ff;
      opacity: 1; }
      .sidebar .nav .nav-item .nav-link.active > i {
        opacity: 1; }

.sidebar .nav .nav-divider {
  color: #2c2d3a;
  border-top: 1px solid #e1eaea; }

@media (min-width: 992px) {
  .sidebar .flex-column, .sidebar-body .flex-column {
    height: calc(100vh - 0px);
    overflow-x: hidden; } }

@media (max-width: 767px) {
  .sidebar-sm.sidebar {
    border-right: 1px solid #e1eaea;
    display: block !important;
    background: inherit !important;
    position: fixed !important;
    top: 0;
    opacity: 1 !important;
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    transition: all .3s;
    -webkit-transition: all .3s; }
    .sidebar-sm.sidebar.show {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); } }

/**chat**/
.media-chat {
  padding-right: 60px;
  padding-bottom: 25px; }
  .media-chat .chat-avatar {
    width: 36px;
    position: relative;
    top: -15px; }
  .media-chat p.chat-time {
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
    opacity: 0.5;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 3px;
    color: #2c2d3a; }
  .media-chat p {
    position: relative;
    padding: 7px 18px;
    margin: 4px 0;
    background-color: #f3f8fa;
    border-radius: 3px;
    float: left;
    clear: left;
    max-width: 55%;
    word-wrap: break-word;
    border-radius: 10px; }
  .media-chat.media-chat-self {
    padding-right: 0px;
    padding-left: 64px; }
    .media-chat.media-chat-self p {
      background-color: #0084ff;
      color: #fff;
      float: right;
      clear: right; }
      .media-chat.media-chat-self p a {
        color: #fff;
        text-decoration: underline; }
    .media-chat.media-chat-self p.chat-time {
      background-color: transparent;
      padding-left: 0px;
      padding-right: 0px;
      opacity: 0.5;
      color: #2c2d3a; }

.chat-new {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  .chat-new .form-control {
    position: absolute;
    left: 0;
    width: 100%;
    border: 0px;
    padding-left: 1rem;
    height: 100%; }
  .chat-new .chat-actions {
    position: absolute;
    right: .5rem;
    top: 0;
    height: 100%; }
    .chat-new .chat-actions button {
      cursor: pointer;
      opacity: .6;
      color: #9ba4ae; }
      .chat-new .chat-actions button:hover {
        opacity: 1; }
    .chat-new .chat-actions .chat-item {
      display: inline-flex;
      height: 100%;
      flex-direction: row;
      font-size: 16px;
      cursor: pointer;
      padding: 0px .5rem; }
  .chat-new .em, .chat-new .em-svg {
    height: 1rem;
    width: 1rem; }

.contacts-list .list-item {
  border-bottom: 1px solid #f2f6f8; }
  .contacts-list .list-item:last-child {
    border-bottom: 0px; }
  .contacts-list .list-item:hover {
    background-color: #f3f8fa; }

.contacts-list .list-thumb {
  overflow: visible !important; }
  .contacts-list .list-thumb .states {
    bottom: 0;
    right: 0;
    top: auto; }

/* 
   Badge tooltip and popovers
*/
.tooltip-inner {
  background-color: #2c2d3a;
  transition: all .3s;
  -webkit-transition: all .3s; }

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #2c2d3a; }

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #2c2d3a; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #2c2d3a; }

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #2c2d3a; }

.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: #e1eaea; }

.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: #e1eaea; }

.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: #e1eaea; }

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #e1eaea; }

.popover {
  border-color: #e1eaea; }

.popover-header {
  background-color: #f3f8fa;
  border-bottom-color: #e1eaea;
  font-weight: 400; }

/*
Block quotes
*/
.blockquote {
  padding-left: .75rem;
  border-left: 3px solid #e5f2ff; }
  .blockquote.blockquote-right {
    padding-left: 0px;
    padding-right: .75rem;
    border-left: 0px;
    border-right: 3px solid #e5f2ff;
    text-align: right; }
  .blockquote.blockquote-center {
    text-align: center;
    padding-left: 0px;
    border-left: 0px; }
  .blockquote .blockquote-footer {
    color: inherit;
    opacity: .6;
    font-size: .857rem; }
  .blockquote.blockquote-dark {
    color: #fff;
    padding: .75rem 1rem; }

/*
Buttons style
*/
.btn-group-sm > .btn {
  padding: .35rem .75rem;
  font-size: .7rem;
  min-width: 1rem; }

.btn-group-lg > .btn {
  padding: .75rem 1.25rem;
  font-size: .9rem; }

.btn-group-xl > .btn {
  padding: .9rem 1.5rem;
  font-size: 1rem; }

.btn-group .btn.active:not(disabled):not(.disabled) {
  box-shadow: inset 0px 0px 2px 1px rgba(44, 45, 58, 0.05) !important; }

.btn {
  font-size: .875rem;
  font-weight: 400;
  padding: .5rem 1rem;
  min-width: 5rem;
  text-align: center;
  overflow: hidden;
  position: relative;
  line-height: 1.25;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .btn.btn-sm, .btn-group-sm > .btn {
    padding: .35rem .75rem;
    font-size: .8rem;
    min-width: 1.5rem; }
    .btn.btn-sm.btn-subtitle .text-block, .btn-group-sm > .btn.btn-subtitle .text-block {
      font-size: 10px; }
  .btn.btn-lg, .btn-group-lg > .btn {
    padding: .75rem 1.25rem;
    font-size: .9rem; }
  .btn.btn-xl {
    padding: .9rem 1.5rem;
    font-size: 1rem; }
  .btn.btn-rounded {
    border-radius: 100px !important;
    -webkit-border-radius: 100px !important; }
  .btn.btn-square {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important; }
  .btn.btn-border0 {
    border: 0px !important; }
    .btn.btn-border0 > * {
      border: 0px !important; }
  .btn.btn-subtitle {
    text-align: center; }
    .btn.btn-subtitle span.text-block {
      opacity: .75;
      font-size: 12px;
      display: block;
      font-weight: 400; }
    .btn.btn-subtitle.btn-icon {
      text-align: left; }
  .btn.btn-icon {
    padding-left: 2.5rem; }
    .btn.btn-icon > i {
      display: inline-flex !important;
      flex-direction: row !important;
      align-items: center !important;
      justify-content: center !important;
      position: absolute;
      left: 0;
      top: 1px;
      height: 100%;
      width: 2.5rem;
      line-height: 1;
      font-size: 14px;
      margin-right: 0px; }
      .btn.btn-icon > i[class^="icon-"], .btn.btn-icon > i[class*=" icon-"] {
        font-size: 21px; }
    .btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon {
      padding-left: 2rem; }
      .btn.btn-icon.btn-sm > i, .btn-group-sm > .btn.btn-icon > i {
        width: 2rem;
        font-size: 12px; }
        .btn.btn-icon.btn-sm > i[class^="icon-"], .btn-group-sm > .btn.btn-icon > i[class^="icon-"], .btn.btn-icon.btn-sm > i[class*=" icon-"], .btn-group-sm > .btn.btn-icon > i[class*=" icon-"] {
          font-size: 16px; }
    .btn.btn-icon.btn-lg, .btn-group-lg > .btn.btn-icon {
      padding-left: 3.5rem; }
      .btn.btn-icon.btn-lg > i, .btn-group-lg > .btn.btn-icon > i {
        width: 3.5rem;
        font-size: 18px; }
        .btn.btn-icon.btn-lg > i[class^="icon-"], .btn-group-lg > .btn.btn-icon > i[class^="icon-"], .btn.btn-icon.btn-lg > i[class*=" icon-"], .btn-group-lg > .btn.btn-icon > i[class*=" icon-"] {
          font-size: 24px; }
    .btn.btn-icon.btn-xl {
      padding-left: 4rem; }
      .btn.btn-icon.btn-xl > i {
        width: 4rem;
        font-size: 28px; }
        .btn.btn-icon.btn-xl > i[class^="icon-"], .btn.btn-icon.btn-xl > i[class*=" icon-"] {
          font-size: 32px; }
    .btn.btn-icon.btn-icon-right {
      padding-right: 2.5rem;
      padding-left: 1rem;
      text-align: right; }
      .btn.btn-icon.btn-icon-right > i {
        right: 0;
        left: auto; }
      .btn.btn-icon.btn-icon-right .btn-subtitle-content {
        text-align: right; }
      .btn.btn-icon.btn-icon-right.btn-sm, .btn-group-sm > .btn.btn-icon.btn-icon-right {
        padding-right: 2rem;
        padding-left: .5rem; }
      .btn.btn-icon.btn-icon-right.btn-lg, .btn-group-lg > .btn.btn-icon.btn-icon-right {
        padding-right: 3.5rem;
        padding-left: 1.25rem; }
      .btn.btn-icon.btn-icon-right.btn-xl {
        padding-right: 4rem;
        padding-left: 1.5rem; }

.btn.disabled, .btn:disabled {
  opacity: .5;
  pointer-events: none; }

/**fill buttons**/
.btn.btn-primary {
  color: #fff;
  background-color: #0084ff;
  border-color: rgba(16, 114, 206, 0.4); }
  .btn.btn-primary:hover, .btn.btn-primary:focus:not(.disabled):not([disabled]), .btn.btn-primary.focus:not(.disabled):not([disabled]), .btn.btn-primary:active:not(.disabled):not([disabled]), .btn.btn-primary.active:not(.disabled):not([disabled]) {
    background-color: #1072ce;
    box-shadow: none;
    border-color: rgba(16, 114, 206, 0.5);
    color: #e5f2ff; }
  .btn.btn-primary.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(0, 132, 255, 0.24) !important; }

.btn.btn-success {
  color: #fff;
  background-color: #94df4a;
  border-color: rgba(125, 200, 53, 0.7); }
  .btn.btn-success:hover, .btn.btn-success:focus:not(.disabled):not([disabled]), .btn.btn-success.focus:not(.disabled):not([disabled]), .btn.btn-success:active:not(.disabled):not([disabled]), .btn.btn-success.active:not(.disabled):not([disabled]) {
    background-color: #7dc835;
    box-shadow: none;
    border-color: #7dc835;
    color: #e5fcd0; }
  .btn.btn-success.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(148, 223, 74, 0.24) !important; }

.btn.btn-info {
  color: #fff;
  background-color: #86d4f5;
  border-color: rgba(105, 193, 230, 0.7); }
  .btn.btn-info:hover, .btn.btn-info:focus:not(.disabled):not([disabled]), .btn.btn-info.focus:not(.disabled):not([disabled]), .btn.btn-info:active:not(.disabled):not([disabled]), .btn.btn-info.active:not(.disabled):not([disabled]) {
    background-color: #69c1e6;
    box-shadow: none;
    border-color: #69c1e6;
    color: #e7f7fe; }
  .btn.btn-info.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(134, 212, 245, 0.24) !important; }

.btn.btn-danger {
  color: #fff;
  background-color: #fa625e;
  border-color: rgba(227, 66, 61, 0.7); }
  .btn.btn-danger:hover, .btn.btn-danger:focus:not(.disabled):not([disabled]), .btn.btn-danger.focus:not(.disabled):not([disabled]), .btn.btn-danger:active:not(.disabled):not([disabled]), .btn.btn-danger.active:not(.disabled):not([disabled]) {
    background-color: #e3423d;
    box-shadow: none;
    border-color: #e3423d;
    color: #fee5e4; }
  .btn.btn-danger.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(250, 98, 94, 0.24) !important; }

.btn.btn-warning {
  color: #fff;
  background-color: #f3d768;
  border-color: rgba(223, 193, 74, 0.7); }
  .btn.btn-warning:hover, .btn.btn-warning:focus:not(.disabled):not([disabled]), .btn.btn-warning.focus:not(.disabled):not([disabled]), .btn.btn-warning:active:not(.disabled):not([disabled]), .btn.btn-warning.active:not(.disabled):not([disabled]) {
    background-color: #dfc14a;
    box-shadow: none;
    border-color: #dfc14a;
    color: #fff;
    color: #fef8e2; }
  .btn.btn-warning.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(243, 215, 104, 0.24) !important; }

.btn.btn-secondary {
  color: #fff;
  background-color: #a8adb9;
  border-color: rgba(130, 136, 151, 0.1); }
  .btn.btn-secondary:hover, .btn.btn-secondary:focus:not(.disabled):not([disabled]), .btn.btn-secondary.focus:not(.disabled):not([disabled]), .btn.btn-secondary:active:not(.disabled):not([disabled]), .btn.btn-secondary.active:not(.disabled):not([disabled]) {
    background-color: #828897;
    box-shadow: none;
    border-color: #828897;
    color: #dfe0e3; }
  .btn.btn-secondary.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(168, 173, 185, 0.24) !important; }

.btn.btn-teal {
  color: #fff;
  background-color: #2cddbe;
  border-color: rgba(32, 199, 170, 0.7); }
  .btn.btn-teal:hover, .btn.btn-teal:focus:not(.disabled):not([disabled]), .btn.btn-teal.focus:not(.disabled):not([disabled]), .btn.btn-teal:active:not(.disabled):not([disabled]), .btn.btn-teal.active:not(.disabled):not([disabled]) {
    background-color: #20c7aa;
    box-shadow: none;
    border-color: #20c7aa;
    color: #d9fff8; }
  .btn.btn-teal.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 221, 190, 0.24) !important; }

.btn.btn-purple {
  color: #fff;
  background-color: #a95ec7;
  border-color: rgba(143, 63, 175, 0.7); }
  .btn.btn-purple:hover, .btn.btn-purple:focus:not(.disabled):not([disabled]), .btn.btn-purple.focus:not(.disabled):not([disabled]), .btn.btn-purple:active:not(.disabled):not([disabled]), .btn.btn-purple.active:not(.disabled):not([disabled]) {
    background-color: #8f3faf;
    box-shadow: none;
    border-color: #8f3faf;
    color: #f4daff; }
  .btn.btn-purple.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(169, 94, 199, 0.24) !important; }

.btn.btn-dark {
  color: #fff;
  background-color: #2c2d3a;
  border-color: rgba(38, 39, 52, 0.7); }
  .btn.btn-dark:hover, .btn.btn-dark:focus:not(.disabled):not([disabled]), .btn.btn-dark.focus:not(.disabled):not([disabled]), .btn.btn-dark:active:not(.disabled):not([disabled]), .btn.btn-dark.active:not(.disabled):not([disabled]) {
    background-color: #262734;
    box-shadow: none;
    color: #bcbdc5;
    border-color: #262734; }
  .btn.btn-dark.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 45, 58, 0.24) !important; }

.btn.btn-light {
  color: #182359;
  background-color: #f3f8fa;
  border-color: #f2f6f8; }
  .btn.btn-light:hover, .btn.btn-light:focus:not(.disabled):not([disabled]), .btn.btn-light.focus:not(.disabled):not([disabled]), .btn.btn-light:active:not(.disabled):not([disabled]), .btn.btn-light.active:not(.disabled):not([disabled]) {
    background-color: #f2f6f8;
    box-shadow: none;
    border-color: #e1eaea;
    color: #2c2d3a; }
  .btn.btn-light.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(242, 246, 248, 0.99) !important;
    border-color: rgba(44, 45, 58, 0.1); }

.btn.btn-pink {
  color: #fff;
  background-color: #f77dc2;
  border-color: rgba(218, 85, 160, 0.7); }
  .btn.btn-pink:hover, .btn.btn-pink:focus:not(.disabled):not([disabled]), .btn.btn-pink.focus:not(.disabled):not([disabled]), .btn.btn-pink:active:not(.disabled):not([disabled]), .btn.btn-pink.active:not(.disabled):not([disabled]) {
    background-color: #da55a0;
    box-shadow: none;
    border-color: #da55a0;
    color: #fde6f3; }
  .btn.btn-pink.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(247, 125, 194, 0.24) !important; }

.btn.btn-link {
  color: #0084ff !important;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: .25rem;
  border-bottom: 1px solid;
  border-radius: 0px;
  border-bottom-color: rgba(16, 114, 206, 0.2); }

/***light buttons**/
.btn.btn-light-primary {
  background-color: #e5f2ff;
  color: #0084ff;
  border-color: rgba(0, 132, 255, 0.2); }
  .btn.btn-light-primary:hover, .btn.btn-light-primary:focus:not(.disabled):not([disabled]), .btn.btn-light-primary.focus:not(.disabled):not([disabled]), .btn.btn-light-primary:active:not(.disabled):not([disabled]), .btn.btn-light-primary.active:not(.disabled):not([disabled]) {
    background-color: #0084ff;
    box-shadow: none;
    border-color: rgba(16, 114, 206, 0.4);
    color: #e5f2ff; }
  .btn.btn-light-primary.btn-shadow {
    box-shadow: 0px 5px 10px 2px #e5f2ff !important; }

.btn.btn-light-info {
  background-color: #e7f7fe;
  color: #86d4f5;
  border-color: rgba(134, 212, 245, 0.2); }
  .btn.btn-light-info:hover, .btn.btn-light-info:focus:not(.disabled):not([disabled]), .btn.btn-light-info.focus:not(.disabled):not([disabled]), .btn.btn-light-info:active:not(.disabled):not([disabled]), .btn.btn-light-info.active:not(.disabled):not([disabled]) {
    background-color: #86d4f5;
    box-shadow: none;
    border-color: rgba(105, 193, 230, 0.4);
    color: #e7f7fe; }
  .btn.btn-light-info.btn-shadow {
    box-shadow: 0px 5px 10px 2px #e7f7fe !important; }

.btn.btn-light-success {
  background-color: #e5fcd0;
  color: #94df4a;
  border-color: rgba(148, 223, 74, 0.2); }
  .btn.btn-light-success:hover, .btn.btn-light-success:focus:not(.disabled):not([disabled]), .btn.btn-light-success.focus:not(.disabled):not([disabled]), .btn.btn-light-success:active:not(.disabled):not([disabled]), .btn.btn-light-success.active:not(.disabled):not([disabled]) {
    background-color: #94df4a;
    box-shadow: none;
    border-color: rgba(125, 200, 53, 0.4);
    color: #e5fcd0; }
  .btn.btn-light-success.btn-shadow {
    box-shadow: 0px 5px 10px 2px #e5fcd0 !important; }

.btn.btn-light-warning {
  background-color: #fef8e2;
  color: #f3d768;
  border-color: rgba(243, 215, 104, 0.2); }
  .btn.btn-light-warning:hover, .btn.btn-light-warning:focus:not(.disabled):not([disabled]), .btn.btn-light-warning.focus:not(.disabled):not([disabled]), .btn.btn-light-warning:active:not(.disabled):not([disabled]), .btn.btn-light-warning.active:not(.disabled):not([disabled]) {
    background-color: #f3d768;
    box-shadow: none;
    border-color: rgba(223, 193, 74, 0.4);
    color: #fef8e2; }
  .btn.btn-light-warning.btn-shadow {
    box-shadow: 0px 5px 10px 2px #fef8e2 !important; }

.btn.btn-light-danger {
  background-color: #fee5e4;
  color: #fa625e;
  border-color: rgba(250, 98, 94, 0.2); }
  .btn.btn-light-danger:hover, .btn.btn-light-danger:focus:not(.disabled):not([disabled]), .btn.btn-light-danger.focus:not(.disabled):not([disabled]), .btn.btn-light-danger:active:not(.disabled):not([disabled]), .btn.btn-light-danger.active:not(.disabled):not([disabled]) {
    background-color: #fa625e;
    box-shadow: none;
    border-color: rgba(227, 66, 61, 0.4);
    color: #fee5e4; }
  .btn.btn-light-danger.btn-shadow {
    box-shadow: 0px 5px 10px 2px #fee5e4 !important; }

.btn.btn-light-teal {
  background-color: #d9fff8;
  color: #2cddbe;
  border-color: rgba(44, 221, 190, 0.2); }
  .btn.btn-light-teal:hover, .btn.btn-light-teal:focus:not(.disabled):not([disabled]), .btn.btn-light-teal.focus:not(.disabled):not([disabled]), .btn.btn-light-teal:active:not(.disabled):not([disabled]), .btn.btn-light-teal.active:not(.disabled):not([disabled]) {
    background-color: #2cddbe;
    box-shadow: none;
    border-color: rgba(32, 199, 170, 0.4);
    color: #d9fff8; }
  .btn.btn-light-teal.btn-shadow {
    box-shadow: 0px 5px 10px 2px #d9fff8 !important; }

.btn.btn-light-purple {
  background-color: #f4daff;
  color: #a95ec7;
  border-color: rgba(169, 94, 199, 0.2); }
  .btn.btn-light-purple:hover, .btn.btn-light-purple:focus:not(.disabled):not([disabled]), .btn.btn-light-purple.focus:not(.disabled):not([disabled]), .btn.btn-light-purple:active:not(.disabled):not([disabled]), .btn.btn-light-purple.active:not(.disabled):not([disabled]) {
    background-color: #a95ec7;
    box-shadow: none;
    border-color: rgba(143, 63, 175, 0.4);
    color: #f4daff; }
  .btn.btn-light-purple.btn-shadow {
    box-shadow: 0px 5px 10px 2px #f4daff !important; }

.btn.btn-light-dark {
  background-color: #bcbdc5;
  color: #2c2d3a;
  border-color: rgba(44, 45, 58, 0.2); }
  .btn.btn-light-dark:hover, .btn.btn-light-dark:focus:not(.disabled):not([disabled]), .btn.btn-light-dark.focus:not(.disabled):not([disabled]), .btn.btn-light-dark:active:not(.disabled):not([disabled]), .btn.btn-light-dark.active:not(.disabled):not([disabled]) {
    background-color: #2c2d3a;
    box-shadow: none;
    border-color: rgba(38, 39, 52, 0.4);
    color: #bcbdc5; }
  .btn.btn-light-dark.btn-shadow {
    box-shadow: 0px 5px 10px 2px #bcbdc5 !important; }

.btn.btn-light-pink {
  background-color: #fde6f3;
  color: #f77dc2;
  border-color: rgba(247, 125, 194, 0.2); }
  .btn.btn-light-pink:hover, .btn.btn-light-pink:focus:not(.disabled):not([disabled]), .btn.btn-light-pink.focus:not(.disabled):not([disabled]), .btn.btn-light-pink:active:not(.disabled):not([disabled]), .btn.btn-light-pink.active:not(.disabled):not([disabled]) {
    background-color: #f77dc2;
    box-shadow: none;
    border-color: rgba(218, 85, 160, 0.4);
    color: #fde6f3; }
  .btn.btn-light-pink.btn-shadow {
    box-shadow: 0px 5px 10px 2px #fde6f3 !important; }

.btn.btn-outline-light-primary {
  background-color: transparent;
  color: #0084ff;
  border-color: #e5f2ff; }
  .btn.btn-outline-light-primary:hover, .btn.btn-outline-light-primary:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-primary.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-primary:active:not(.disabled):not([disabled]), .btn.btn-outline-light-primary.active:not(.disabled):not([disabled]) {
    background-color: #e5f2ff;
    box-shadow: none;
    border-color: rgba(0, 132, 255, 0.2);
    color: #0084ff; }
  .btn.btn-outline-light-primary.btn-shadow {
    box-shadow: 0px 5px 10px 2px #e5f2ff !important; }

.btn.btn-outline-light-success {
  background-color: transparent;
  color: #94df4a;
  border-color: #e5fcd0; }
  .btn.btn-outline-light-success:hover, .btn.btn-outline-light-success:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-success.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-success:active:not(.disabled):not([disabled]), .btn.btn-outline-light-success.active:not(.disabled):not([disabled]) {
    background-color: #e5fcd0;
    box-shadow: none;
    border-color: rgba(148, 223, 74, 0.2);
    color: #94df4a; }
  .btn.btn-outline-light-success.btn-shadow {
    box-shadow: 0px 5px 10px 2px #e5fcd0 !important; }

.btn.btn-outline-light-danger {
  background-color: transparent;
  color: #fa625e;
  border-color: #fee5e4; }
  .btn.btn-outline-light-danger:hover, .btn.btn-outline-light-danger:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-danger.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-danger:active:not(.disabled):not([disabled]), .btn.btn-outline-light-danger.active:not(.disabled):not([disabled]) {
    background-color: #fee5e4;
    box-shadow: none;
    border-color: rgba(250, 98, 94, 0.2);
    color: #fa625e; }
  .btn.btn-outline-light-danger.btn-shadow {
    box-shadow: 0px 5px 10px 2px #fee5e4 !important; }

.btn.btn-outline-light-warning {
  background-color: transparent;
  color: #f3d768;
  border-color: #fef8e2; }
  .btn.btn-outline-light-warning:hover, .btn.btn-outline-light-warning:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-warning.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-warning:active:not(.disabled):not([disabled]), .btn.btn-outline-light-warning.active:not(.disabled):not([disabled]) {
    background-color: #fef8e2;
    box-shadow: none;
    border-color: rgba(243, 215, 104, 0.2);
    color: #f3d768; }
  .btn.btn-outline-light-warning.btn-shadow {
    box-shadow: 0px 5px 10px 2px #fef8e2 !important; }

.btn.btn-outline-light-info {
  background-color: transparent;
  color: #86d4f5;
  border-color: #e7f7fe; }
  .btn.btn-outline-light-info:hover, .btn.btn-outline-light-info:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-info.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-info:active:not(.disabled):not([disabled]), .btn.btn-outline-light-info.active:not(.disabled):not([disabled]) {
    background-color: #e7f7fe;
    box-shadow: none;
    border-color: rgba(134, 212, 245, 0.2);
    color: #86d4f5; }
  .btn.btn-outline-light-info.btn-shadow {
    box-shadow: 0px 5px 10px 2px #e7f7fe !important; }

.btn.btn-outline-light-teal {
  background-color: transparent;
  color: #2cddbe;
  border-color: #d9fff8; }
  .btn.btn-outline-light-teal:hover, .btn.btn-outline-light-teal:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-teal.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-teal:active:not(.disabled):not([disabled]), .btn.btn-outline-light-teal.active:not(.disabled):not([disabled]) {
    background-color: #d9fff8;
    box-shadow: none;
    border-color: rgba(44, 221, 190, 0.2);
    color: #2cddbe; }
  .btn.btn-outline-light-teal.btn-shadow {
    box-shadow: 0px 5px 10px 2px #d9fff8 !important; }

.btn.btn-outline-light-purple {
  background-color: transparent;
  color: #a95ec7;
  border-color: #f4daff; }
  .btn.btn-outline-light-purple:hover, .btn.btn-outline-light-purple:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-purple.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-purple:active:not(.disabled):not([disabled]), .btn.btn-outline-light-purple.active:not(.disabled):not([disabled]) {
    background-color: #f4daff;
    box-shadow: none;
    border-color: rgba(169, 94, 199, 0.2);
    color: #a95ec7; }
  .btn.btn-outline-light-purple.btn-shadow {
    box-shadow: 0px 5px 10px 2px #f4daff !important; }

.btn.btn-outline-light-pink {
  background-color: transparent;
  color: #f77dc2;
  border-color: #fde6f3; }
  .btn.btn-outline-light-pink:hover, .btn.btn-outline-light-pink:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-pink.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-pink:active:not(.disabled):not([disabled]), .btn.btn-outline-light-pink.active:not(.disabled):not([disabled]) {
    background-color: #fde6f3;
    box-shadow: none;
    border-color: rgba(247, 125, 194, 0.2);
    color: #f77dc2; }
  .btn.btn-outline-light-pink.btn-shadow {
    box-shadow: 0px 5px 10px 2px #fde6f3 !important; }

.btn.btn-outline-light-dark {
  background-color: transparent;
  color: #2c2d3a;
  border-color: #bcbdc5; }
  .btn.btn-outline-light-dark:hover, .btn.btn-outline-light-dark:focus:not(.disabled):not([disabled]), .btn.btn-outline-light-dark.focus:not(.disabled):not([disabled]), .btn.btn-outline-light-dark:active:not(.disabled):not([disabled]), .btn.btn-outline-light-dark.active:not(.disabled):not([disabled]) {
    background-color: #bcbdc5;
    box-shadow: none;
    border-color: rgba(44, 45, 58, 0.2);
    color: #2c2d3a; }
  .btn.btn-outline-light-dark.btn-shadow {
    box-shadow: 0px 5px 10px 2px #bcbdc5 !important; }

/**btn gradient**/
.btn.btn-gradient {
  border: 1px solid; }

.btn.btn-gradient:not(.btn-gradient-light) {
  color: #fff; }

.btn.btn-gradient-primary {
  color: #fff;
  background-color: #0084ff;
  background: -webkit-gradient(linear, right top, left top, from(#0084ff), to(#1072ce));
  background: linear-gradient(-90deg, #0084ff, #1072ce);
  background: -webkit-linear-gradient(-90deg, #0084ff, #1072ce);
  border-color: #1072ce; }
  .btn.btn-gradient-primary:hover, .btn.btn-gradient-primary:focus:not(.disabled):not([disabled]), .btn.btn-gradient-primary.focus:not(.disabled):not([disabled]), .btn.btn-gradient-primary:active:not(.disabled):not([disabled]), .btn.btn-gradient-primary.active:not(.disabled):not([disabled]) {
    background-color: #0084ff;
    background: -webkit-gradient(linear, right top, left top, from(#1072ce), to(#1072ce));
    background: linear-gradient(-90deg, #1072ce, #1072ce);
    background: -webkit-linear-gradient(-90deg, #1072ce, #1072ce);
    border-color: #1072ce;
    box-shadow: none; }
  .btn.btn-gradient-primary.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(0, 132, 255, 0.24) !important; }

.btn.btn-gradient-success {
  color: #fff;
  background-color: #94df4a;
  background: -webkit-gradient(linear, right top, left top, from(#94df4a), to(#7dc835));
  background: linear-gradient(-90deg, #94df4a, #7dc835);
  background: -webkit-linear-gradient(-90deg, #94df4a, #7dc835);
  border-color: #7dc835; }
  .btn.btn-gradient-success:hover, .btn.btn-gradient-success:focus:not(.disabled):not([disabled]), .btn.btn-gradient-success.focus:not(.disabled):not([disabled]), .btn.btn-gradient-success:active:not(.disabled):not([disabled]), .btn.btn-gradient-success.active:not(.disabled):not([disabled]) {
    background-color: #94df4a;
    background: -webkit-gradient(linear, right top, left top, from(#7dc835), to(#7dc835));
    background: linear-gradient(-90deg, #7dc835, #7dc835);
    background: -webkit-linear-gradient(-90deg, #7dc835, #7dc835);
    border-color: #7dc835;
    box-shadow: none; }
  .btn.btn-gradient-success.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(148, 223, 74, 0.24) !important; }

.btn.btn-gradient-info {
  color: #fff;
  background-color: #86d4f5;
  background: -webkit-gradient(linear, right top, left top, from(#86d4f5), to(#69c1e6));
  background: linear-gradient(-90deg, #86d4f5, #69c1e6);
  background: -webkit-linear-gradient(-90deg, #86d4f5, #69c1e6);
  border-color: #69c1e6; }
  .btn.btn-gradient-info:hover, .btn.btn-gradient-info:focus:not(.disabled):not([disabled]), .btn.btn-gradient-info.focus:not(.disabled):not([disabled]), .btn.btn-gradient-info:active:not(.disabled):not([disabled]), .btn.btn-gradient-info.active:not(.disabled):not([disabled]) {
    background-color: #86d4f5;
    background: -webkit-gradient(linear, right top, left top, from(#69c1e6), to(#69c1e6));
    background: linear-gradient(-90deg, #69c1e6, #69c1e6);
    background: -webkit-linear-gradient(-90deg, #69c1e6, #69c1e6);
    border-color: #69c1e6;
    box-shadow: none; }
  .btn.btn-gradient-info.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(134, 212, 245, 0.24) !important; }

.btn.btn-gradient-danger {
  color: #fff;
  background-color: #fa625e;
  background: -webkit-gradient(linear, right top, left top, from(#fa625e), to(#e3423d));
  background: linear-gradient(-90deg, #fa625e, #e3423d);
  background: -webkit-linear-gradient(-90deg, #fa625e, #e3423d);
  border-color: #e3423d; }
  .btn.btn-gradient-danger:hover, .btn.btn-gradient-danger:focus:not(.disabled):not([disabled]), .btn.btn-gradient-danger.focus:not(.disabled):not([disabled]), .btn.btn-gradient-danger:active:not(.disabled):not([disabled]), .btn.btn-gradient-danger.active:not(.disabled):not([disabled]) {
    background-color: #fa625e;
    background: -webkit-gradient(linear, right top, left top, from(#e3423d), to(#e3423d));
    background: linear-gradient(-90deg, #e3423d, #e3423d);
    background: -webkit-linear-gradient(-90deg, #e3423d, #e3423d);
    border-color: #e3423d;
    box-shadow: none; }
  .btn.btn-gradient-danger.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(250, 98, 94, 0.24) !important; }

.btn.btn-gradient-warning {
  color: #fff;
  background-color: #f3d768;
  background: -webkit-gradient(linear, right top, left top, from(#f3d768), to(#dfc14a));
  background: linear-gradient(-90deg, #f3d768, #dfc14a);
  background: -webkit-linear-gradient(-90deg, #f3d768, #dfc14a);
  border-color: #dfc14a; }
  .btn.btn-gradient-warning:hover, .btn.btn-gradient-warning:focus:not(.disabled):not([disabled]), .btn.btn-gradient-warning.focus:not(.disabled):not([disabled]), .btn.btn-gradient-warning:active:not(.disabled):not([disabled]), .btn.btn-gradient-warning.active:not(.disabled):not([disabled]) {
    background-color: #f3d768;
    background: -webkit-gradient(linear, right top, left top, from(#dfc14a), to(#dfc14a));
    background: linear-gradient(-90deg, #dfc14a, #dfc14a);
    background: -webkit-linear-gradient(-90deg, #dfc14a, #dfc14a);
    border-color: #dfc14a;
    box-shadow: none; }
  .btn.btn-gradient-warning.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(243, 215, 104, 0.24) !important; }

.btn.btn-gradient-teal {
  color: #fff;
  background-color: #2cddbe;
  background: -webkit-gradient(linear, right top, left top, from(#2cddbe), to(#20c7aa));
  background: linear-gradient(-90deg, #2cddbe, #20c7aa);
  background: -webkit-linear-gradient(-90deg, #2cddbe, #20c7aa);
  border-color: #20c7aa; }
  .btn.btn-gradient-teal:hover, .btn.btn-gradient-teal:focus:not(.disabled):not([disabled]), .btn.btn-gradient-teal.focus:not(.disabled):not([disabled]), .btn.btn-gradient-teal:active:not(.disabled):not([disabled]), .btn.btn-gradient-teal.active:not(.disabled):not([disabled]) {
    background-color: #2cddbe;
    background: -webkit-gradient(linear, right top, left top, from(#20c7aa), to(#20c7aa));
    background: linear-gradient(-90deg, #20c7aa, #20c7aa);
    background: -webkit-linear-gradient(-90deg, #20c7aa, #20c7aa);
    border-color: #20c7aa;
    box-shadow: none; }
  .btn.btn-gradient-teal.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 221, 190, 0.24) !important; }

.btn.btn-gradient-purple {
  color: #fff;
  background-color: #a95ec7;
  background: -webkit-gradient(linear, right top, left top, from(#a95ec7), to(#8f3faf));
  background: linear-gradient(-90deg, #a95ec7, #8f3faf);
  background: -webkit-linear-gradient(-90deg, #a95ec7, #8f3faf);
  border-color: #8f3faf; }
  .btn.btn-gradient-purple:hover, .btn.btn-gradient-purple:focus:not(.disabled):not([disabled]), .btn.btn-gradient-purple.focus:not(.disabled):not([disabled]), .btn.btn-gradient-purple:active:not(.disabled):not([disabled]), .btn.btn-gradient-purple.active:not(.disabled):not([disabled]) {
    background-color: #a95ec7;
    background: -webkit-gradient(linear, right top, left top, from(#8f3faf), to(#8f3faf));
    background: linear-gradient(-90deg, #8f3faf, #8f3faf);
    background: -webkit-linear-gradient(-90deg, #8f3faf, #8f3faf);
    border-color: #8f3faf;
    box-shadow: none; }
  .btn.btn-gradient-purple.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(169, 94, 199, 0.24) !important; }

.btn.btn-gradient-pink {
  color: #fff;
  background-color: #f77dc2;
  background: -webkit-gradient(linear, right top, left top, from(#f77dc2), to(#da55a0));
  background: linear-gradient(-90deg, #f77dc2, #da55a0);
  background: -webkit-linear-gradient(-90deg, #f77dc2, #da55a0);
  border-color: #da55a0; }
  .btn.btn-gradient-pink:hover, .btn.btn-gradient-pink:focus:not(.disabled):not([disabled]), .btn.btn-gradient-pink.focus:not(.disabled):not([disabled]), .btn.btn-gradient-pink:active:not(.disabled):not([disabled]), .btn.btn-gradient-pink.active:not(.disabled):not([disabled]) {
    background-color: #f77dc2;
    background: -webkit-gradient(linear, right top, left top, from(#da55a0), to(#da55a0));
    background: linear-gradient(-90deg, #da55a0, #da55a0);
    background: -webkit-linear-gradient(-90deg, #da55a0, #da55a0);
    border-color: #da55a0;
    box-shadow: none; }
  .btn.btn-gradient-pink.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(247, 125, 194, 0.24) !important; }

.btn.btn-gradient-dark {
  color: #fff;
  background-color: #2c2d3a;
  background: -webkit-gradient(linear, right top, left top, from(#2c2d3a), to(#262734));
  background: linear-gradient(-90deg, #2c2d3a, #262734);
  background: -webkit-linear-gradient(-90deg, #2c2d3a, #262734);
  border-color: #262734; }
  .btn.btn-gradient-dark:hover, .btn.btn-gradient-dark:focus:not(.disabled):not([disabled]), .btn.btn-gradient-dark.focus:not(.disabled):not([disabled]), .btn.btn-gradient-dark:active:not(.disabled):not([disabled]), .btn.btn-gradient-dark.active:not(.disabled):not([disabled]) {
    background-color: #2c2d3a;
    background: -webkit-gradient(linear, right top, left top, from(#262734), to(#262734));
    background: linear-gradient(-90deg, #262734, #262734);
    background: -webkit-linear-gradient(-90deg, #262734, #262734);
    border-color: #262734;
    box-shadow: none; }
  .btn.btn-gradient-dark.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 45, 58, 0.24) !important; }

.btn.btn-gradient-secondary {
  color: #fff;
  background-color: #a8adb9;
  background: -webkit-gradient(linear, right top, left top, from(#a8adb9), to(#828897));
  background: linear-gradient(-90deg, #a8adb9, #828897);
  background: -webkit-linear-gradient(-90deg, #a8adb9, #828897);
  border-color: rgba(130, 136, 151, 0.75); }
  .btn.btn-gradient-secondary:hover, .btn.btn-gradient-secondary:focus:not(.disabled):not([disabled]), .btn.btn-gradient-secondary.focus:not(.disabled):not([disabled]), .btn.btn-gradient-secondary:active:not(.disabled):not([disabled]), .btn.btn-gradient-secondary.active:not(.disabled):not([disabled]) {
    background-color: #a8adb9;
    background: -webkit-gradient(linear, right top, left top, from(#a8adb9), to(#a8adb9));
    background: linear-gradient(-90deg, #a8adb9, #a8adb9);
    background: -webkit-linear-gradient(-90deg, #a8adb9, #a8adb9);
    border-color: rgba(130, 136, 151, 0.7);
    box-shadow: none; }
  .btn.btn-gradient-secondary.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(168, 173, 185, 0.24) !important; }

.btn.btn-gradient-light {
  background-color: #fff;
  background: -webkit-gradient(linear, right top, left top, from(#fff), to(#f3f8fa));
  background: linear-gradient(-90deg, #fff, #f3f8fa);
  background: -webkit-linear-gradient(-90deg, #fff, #f3f8fa);
  border-color: #f2f6f8; }
  .btn.btn-gradient-light:hover, .btn.btn-gradient-light:focus:not(.disabled):not([disabled]), .btn.btn-gradient-light.focus:not(.disabled):not([disabled]), .btn.btn-gradient-light:active:not(.disabled):not([disabled]), .btn.btn-gradient-light.active:not(.disabled):not([disabled]) {
    background: -webkit-gradient(linear, right top, left top, from(#f3f8fa), to(#f3f8fa));
    background: linear-gradient(-90deg, #f3f8fa, #f3f8fa);
    background: -webkit-linear-gradient(-90deg, #f3f8fa, #f3f8fa);
    border-color: #e1eaea;
    color: #9ba4ae;
    box-shadow: none; }
  .btn.btn-gradient-light.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 45, 58, 0.1) !important;
    border-color: #e1eaea; }

/**outline buttons**/
.btn.btn-outline-primary {
  border-color: #0084ff;
  color: #0084ff;
  background-color: transparent; }
  .btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus:not(.disabled):not([disabled]), .btn.btn-outline-primary.focus:not(.disabled):not([disabled]), .btn.btn-outline-primary:active:not(.disabled):not([disabled]), .btn.btn-outline-primary.active:not(.disabled):not([disabled]) {
    background-color: #0084ff;
    box-shadow: none;
    border-color: rgba(16, 114, 206, 0.5);
    color: #e5f2ff; }
  .btn.btn-outline-primary.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(0, 132, 255, 0.24) !important; }

.btn.btn-outline-light {
  border-color: #f2f6f8;
  color: #f2f6f8;
  background-color: transparent; }
  .btn.btn-outline-light:hover, .btn.btn-outline-light:focus:not(.disabled):not([disabled]), .btn.btn-outline-light.focus:not(.disabled):not([disabled]), .btn.btn-outline-light:active:not(.disabled):not([disabled]), .btn.btn-outline-light.active:not(.disabled):not([disabled]) {
    background-color: #f2f6f8;
    box-shadow: none;
    border-color: #e1eaea;
    color: #9ba4ae; }
  .btn.btn-outline-light.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 45, 58, 0.1) !important;
    border-color: rgba(44, 45, 58, 0.1); }

.btn.btn-outline-success {
  border-color: #94df4a;
  color: #94df4a;
  background-color: transparent; }
  .btn.btn-outline-success:hover, .btn.btn-outline-success:focus:not(.disabled):not([disabled]), .btn.btn-outline-success.focus:not(.disabled):not([disabled]), .btn.btn-outline-success:active:not(.disabled):not([disabled]), .btn.btn-outline-success.active:not(.disabled):not([disabled]) {
    background-color: #94df4a;
    box-shadow: none;
    border-color: rgba(125, 200, 53, 0.5);
    color: #e5fcd0; }
  .btn.btn-outline-success.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(148, 223, 74, 0.24) !important; }

.btn.btn-outline-info {
  border-color: #86d4f5;
  color: #86d4f5;
  background-color: transparent; }
  .btn.btn-outline-info:hover, .btn.btn-outline-info:focus:not(.disabled):not([disabled]), .btn.btn-outline-info.focus:not(.disabled):not([disabled]), .btn.btn-outline-info:active:not(.disabled):not([disabled]), .btn.btn-outline-info.active:not(.disabled):not([disabled]) {
    background-color: #86d4f5;
    box-shadow: none;
    border-color: rgba(105, 193, 230, 0.5);
    color: #e7f7fe; }
  .btn.btn-outline-info.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(134, 212, 245, 0.24) !important; }

.btn.btn-outline-warning {
  border-color: #f3d768;
  color: #f3d768;
  background-color: transparent; }
  .btn.btn-outline-warning:hover, .btn.btn-outline-warning:focus:not(.disabled):not([disabled]), .btn.btn-outline-warning.focus:not(.disabled):not([disabled]), .btn.btn-outline-warning:active:not(.disabled):not([disabled]), .btn.btn-outline-warning.active:not(.disabled):not([disabled]) {
    background-color: #f3d768;
    box-shadow: none;
    border-color: rgba(223, 193, 74, 0.5);
    color: #fff;
    color: #fef8e2; }
  .btn.btn-outline-warning.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(243, 215, 104, 0.24) !important; }

.btn.btn-outline-danger {
  border-color: #fa625e;
  color: #fa625e;
  background-color: transparent; }
  .btn.btn-outline-danger:hover, .btn.btn-outline-danger:focus:not(.disabled):not([disabled]), .btn.btn-outline-danger.focus:not(.disabled):not([disabled]), .btn.btn-outline-danger:active:not(.disabled):not([disabled]), .btn.btn-outline-danger.active:not(.disabled):not([disabled]) {
    background-color: #fa625e;
    box-shadow: none;
    border-color: rgba(227, 66, 61, 0.5);
    color: #fee5e4; }
  .btn.btn-outline-danger.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(250, 98, 94, 0.24) !important; }

.btn.btn-outline-teal {
  border-color: #2cddbe;
  color: #2cddbe;
  background-color: transparent; }
  .btn.btn-outline-teal:hover, .btn.btn-outline-teal:focus:not(.disabled):not([disabled]), .btn.btn-outline-teal.focus:not(.disabled):not([disabled]), .btn.btn-outline-teal:active:not(.disabled):not([disabled]), .btn.btn-outline-teal.active:not(.disabled):not([disabled]) {
    background-color: #2cddbe;
    box-shadow: none;
    border-color: rgba(32, 199, 170, 0.5);
    color: #d9fff8; }
  .btn.btn-outline-teal.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 221, 190, 0.24) !important; }

.btn.btn-outline-purple {
  border-color: #a95ec7;
  color: #a95ec7;
  background-color: transparent; }
  .btn.btn-outline-purple:hover, .btn.btn-outline-purple:focus:not(.disabled):not([disabled]), .btn.btn-outline-purple.focus:not(.disabled):not([disabled]), .btn.btn-outline-purple:active:not(.disabled):not([disabled]), .btn.btn-outline-purple.active:not(.disabled):not([disabled]) {
    background-color: #a95ec7;
    box-shadow: none;
    border-color: rgba(143, 63, 175, 0.5);
    color: #f4daff; }
  .btn.btn-outline-purple.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(169, 94, 199, 0.24) !important; }

.btn.btn-outline-pink {
  border-color: #f77dc2;
  color: #f77dc2;
  background-color: transparent; }
  .btn.btn-outline-pink:hover, .btn.btn-outline-pink:focus:not(.disabled):not([disabled]), .btn.btn-outline-pink.focus:not(.disabled):not([disabled]), .btn.btn-outline-pink:active:not(.disabled):not([disabled]), .btn.btn-outline-pink.active:not(.disabled):not([disabled]) {
    background-color: #f77dc2;
    box-shadow: none;
    border-color: rgba(218, 85, 160, 0.5);
    color: #fde6f3; }
  .btn.btn-outline-pink.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(247, 125, 194, 0.24) !important; }

.btn.btn-outline-secondary {
  border-color: #a8adb9;
  color: #a8adb9;
  background-color: transparent; }
  .btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:focus:not(.disabled):not([disabled]), .btn.btn-outline-secondary.focus:not(.disabled):not([disabled]), .btn.btn-outline-secondary:active:not(.disabled):not([disabled]), .btn.btn-outline-secondary.active:not(.disabled):not([disabled]) {
    background-color: #a8adb9;
    box-shadow: none;
    border-color: rgba(130, 136, 151, 0.5);
    color: #dfe0e3; }
  .btn.btn-outline-secondary.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(168, 173, 185, 0.24) !important; }

.btn.btn-outline-dark {
  border-color: #2c2d3a;
  color: #2c2d3a;
  background-color: transparent; }
  .btn.btn-outline-dark:hover, .btn.btn-outline-dark:focus:not(.disabled):not([disabled]), .btn.btn-outline-dark.focus:not(.disabled):not([disabled]), .btn.btn-outline-dark:active:not(.disabled):not([disabled]), .btn.btn-outline-dark.active:not(.disabled):not([disabled]) {
    background-color: #2c2d3a;
    box-shadow: none;
    border-color: rgba(38, 39, 52, 0.5);
    color: #fff;
    color: #bcbdc5; }
  .btn.btn-outline-dark.btn-shadow {
    box-shadow: 0px 5px 10px 2px rgba(44, 45, 58, 0.24) !important; }

/**social buttons**/
.si-colored-facebook, .si-facebook:hover {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #3B5998 !important;
  color: #fff !important; }

.si-colored-twitter, .si-twitter:hover {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #00ACEE !important;
  color: #fff !important; }

.si-colored-g-plus, .si-g-plus:hover {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #DD4B39 !important;
  color: #fff !important; }

.si-colored-skype, .si-skype:hover {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #00AFF0 !important;
  color: #fff !important; }

.si-linkedin:hover, .si-colored-linkedin {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #0E76A8 !important;
  color: #fff !important; }

.si-pin:hover, .si-colored-pinterest {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #C8232C !important;
  color: #fff !important; }

.si-rss:hover, .si-colored-rss {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #EE802F !important;
  color: #fff !important; }

.si-pinterest:hover, .si-colored-pinterest {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #C8232C !important;
  color: #fff !important; }

.si-tumblr:hover, .si-colored-tumblr {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #34526F !important;
  color: #fff !important; }

.si-vimeo:hover, .si-colored-vimeo {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #86C9EF !important;
  color: #fff !important; }

.si-digg:hover, .si-colored-digg {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #191919 !important;
  color: #fff !important; }

.si-instagram:hover, .si-colored-instagram {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #3F729B !important;
  color: #fff !important; }

.si-flickr:hover, .si-colored-flickr {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #FF0084 !important;
  color: #fff !important; }

.si-paypal:hover, .si-colored-paypal {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #00588B !important;
  color: #fff !important; }

.si-yahoo:hover, .si-colored-yahoo {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #720E9E !important;
  color: #fff !important; }

.si-android:hover, .si-colored-andriod {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #A4C639 !important;
  color: #fff !important; }

.si-appstore:hover, .si-colored-appstore {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #000 !important;
  color: #fff !important; }

.si-dropbox:hover, .si-colored-dropbox {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #3D9AE8 !important;
  color: #fff !important; }

.si-dribbble:hover, .si-colored-dribbble {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #EA4C89 !important;
  color: #fff !important; }

.si-soundcloud:hover, .si-colored-soundcloud {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #F70 !important;
  color: #fff !important; }

.si-xing:hover, .si-colored-xing {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #126567 !important;
  color: #fff !important; }

.si-phone:hover, .si-colored-phone {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #444 !important;
  color: #fff !important; }

.si-behance:hover, .si-colored-behance {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #053eff !important;
  color: #fff !important; }

.si-github:hover, .si-colored-github {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #171515 !important;
  color: #fff !important; }

.si-stumbleupon:hover, .si-colored-stumbleupon {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #F74425 !important;
  color: #fff !important; }

.si-email:hover, .si-colored-email {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #6567A5 !important;
  color: #fff !important; }

.si-wordpress:hover, .si-colored-wordpress {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #1E8CBE !important;
  color: #fff !important; }

.si-adn:hover, .si-colored-adn {
  background-color: #b94630 !important;
  color: #fff !important; }

.si-bitbucket:hover, .si-colored-bitbucket {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #0f253c !important;
  color: #fff !important; }

.si-window:hover, .si-colored-window {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #0f4bac !important;
  color: #fff !important; }

.si-reddit:hover, .si-colored-reddit {
  border-color: rgba(44, 45, 58, 0.15) !important;
  background-color: #bcddff !important;
  color: #2c2d3a !important;
  border-color: rgba(44, 45, 58, 0.1) !important; }

.btn-icon-o {
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center !important;
  align-items: center !important;
  font-weight: inherit;
  line-height: 1;
  font-size: 1rem;
  border-radius: 4px;
  text-align: center;
  padding: 0px;
  -webkit-border-radius: 4px;
  min-width: auto; }
  .btn-icon-o.btn-border-o {
    border: 1px solid #e1eaea; }
  .btn-icon-o.btn-icon-sm {
    width: 24px;
    height: 24px;
    font-size: .75rem;
    line-height: 24px; }
  .btn-icon-o.btn-icon-lg {
    width: 44px;
    height: 44px;
    font-size: 1.25rem;
    line-height: 44px; }
  .btn-icon-o.btn-icon-xl {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 1.5rem; }

.btn.btn-social.btn-border-o {
  border: 1px solid #e1eaea; }

/**social block buttons**/
/*
App calendar custom
*/
.fc-toolbar.fc-header-toolbar {
  margin-bottom: .5rem; }
  .fc-toolbar.fc-header-toolbar h2 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5; }

.fc-button.fc-state-default {
  background-image: none !important;
  background-color: transparent;
  border: 1px solid #e1eaea;
  box-shadow: none;
  text-transform: capitalize; }
  .fc-button.fc-state-default.fc-state-hover {
    background-color: #f3f8fa; }
  .fc-button.fc-state-default.fc-state-active {
    background-color: #f3f8fa;
    box-shadow: inset 0px 0px 3px 0px rgba(44, 45, 58, 0.05); }

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
  border-color: #e1eaea; }

.fc-event {
  background-color: #0084ff;
  border-color: rgba(16, 114, 206, 0.5);
  border-radius: 0px;
  color: #e5f2ff !important;
  height: auto;
  padding: .5rem .5rem;
  font-weight: 500; }

@media (max-width: 476px) {
  .fc-toolbar.fc-header-toolbar h2 {
    padding-top: 1rem; } }

/*** portlet cards**/
.portlet-box {
  display: block;
  position: relative;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 1px solid #e1eaea;
  width: 100%;
  background-color: #fff; }
  .portlet-box .portlet-header {
    width: 100%;
    position: relative;
    padding: .5rem 1rem;
    min-height: 50px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-color: inherit; }
    .portlet-box .portlet-header h3 {
      font-size: 1rem;
      text-transform: capitalize;
      margin: 0px;
      display: block;
      font-weight: 600;
      color: inherit; }
    .portlet-box .portlet-header .portlet-subtitle {
      font-size: .8rem;
      line-height: 1.5;
      opacity: .6;
      padding-top: 0 !important; }
  .portlet-box .portlet-body {
    width: 100%;
    padding: 1rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
  .portlet-box .portlet-tools {
    position: relative; }
    .portlet-box .portlet-tools .nav .nav-link {
      padding: .2rem .3rem;
      color: inherit; }
      .portlet-box .portlet-tools .nav .nav-link > i {
        line-height: inherit;
        font-size: 13px; }
        .portlet-box .portlet-tools .nav .nav-link > i[class^="icon-"], .portlet-box .portlet-tools .nav .nav-link > i[class*=" icon-"] {
          font-size: 24px; }
    .portlet-box .portlet-tools .nav > * {
      margin-left: .5rem; }

.portlet-box.portlet-primary {
  border-color: #0084ff; }
  .portlet-box.portlet-primary .portlet-header {
    background-color: #0084ff;
    border-bottom-color: #0084ff !important; }

.portlet-box.portlet-info {
  border-color: #86d4f5; }
  .portlet-box.portlet-info .portlet-header {
    background-color: #86d4f5;
    border-bottom-color: #86d4f5 !important; }

.portlet-box.portlet-success {
  border-color: #94df4a; }
  .portlet-box.portlet-success .portlet-header {
    background-color: #94df4a;
    border-bottom-color: #94df4a !important; }

.portlet-box.portlet-danger {
  border-color: #fa625e; }
  .portlet-box.portlet-danger .portlet-header {
    background-color: #fa625e;
    border-bottom-color: #fa625e !important; }

.portlet-box.portlet-warning {
  border-color: #f3d768; }
  .portlet-box.portlet-warning .portlet-header {
    background-color: #f3d768;
    border-bottom-color: #f3d768 !important; }

.portlet-box.portlet-teal {
  border-color: #2cddbe; }
  .portlet-box.portlet-teal .portlet-header {
    background-color: #2cddbe;
    border-bottom-color: #2cddbe !important; }

.portlet-box.portlet-purple {
  border-color: #a95ec7; }
  .portlet-box.portlet-purple .portlet-header {
    background-color: #a95ec7;
    border-bottom-color: #a95ec7 !important; }

.portlet-box.portlet-pink {
  border-color: #f77dc2; }
  .portlet-box.portlet-pink .portlet-header {
    background-color: #f77dc2;
    border-bottom-color: #f77dc2 !important; }

.portlet-box.portlet-dark {
  border-color: #2c2d3a; }
  .portlet-box.portlet-dark .portlet-header {
    background-color: #2c2d3a;
    border-bottom-color: #2c2d3a !important; }

.portlet-box.portlet-secondary {
  border-color: #a8adb9; }
  .portlet-box.portlet-secondary .portlet-header {
    background-color: #a8adb9;
    border-bottom-color: #a8adb9 !important; }

.portlet-box.portlet-light {
  border-color: #e1eaea; }
  .portlet-box.portlet-light .portlet-header {
    background-color: #f3f8fa; }

.portlet-box.portlet-square {
  border-radius: 0px; }
  .portlet-box.portlet-square > * {
    border-radius: 0px !important; }

.portlet-box.portlet-shadow-sm {
  box-shadow: 0 0px 1px #e1eaea;
  border: 0px; }

.portlet-box.portlet-shadow {
  box-shadow: 0 2px 10px rgba(44, 45, 58, 0.1), 0 -2px 0 #e1eaea;
  border: 0px; }

.portlet-box.portlet-shadow-lg {
  box-shadow: 0 5px 40px 12px #e1eaea;
  border: 0px; }

/**portlet dark skin **/
.portlet-box.skin-dark .portlet-header h3, .portlet-box.skin-dark .portlet-header a:not(.dropdown-item):not(.btn), .portlet-box.skin-dark .portlet-header a.nav-link {
  color: #fff; }

.portlet-box.skinFull-dark {
  color: white; }
  .portlet-box.skinFull-dark.portlet-primary {
    background-color: #0084ff;
    border-color: rgba(16, 114, 206, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-primary .portlet-header, .portlet-box.skinFull-dark.portlet-primary .portlet-body {
      background-color: #0084ff; }
    .portlet-box.skinFull-dark.portlet-primary .portlet-header {
      border-bottom-color: rgba(229, 242, 255, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-pink {
    background-color: #f77dc2;
    border-color: rgba(218, 85, 160, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-pink .portlet-header, .portlet-box.skinFull-dark.portlet-pink .portlet-body {
      background-color: #f77dc2; }
    .portlet-box.skinFull-dark.portlet-pink .portlet-header {
      border-bottom-color: rgba(253, 230, 243, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-dark {
    background-color: #2c2d3a;
    border-color: rgba(38, 39, 52, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-dark .portlet-header, .portlet-box.skinFull-dark.portlet-dark .portlet-body {
      background-color: #2c2d3a; }
    .portlet-box.skinFull-dark.portlet-dark .portlet-header {
      border-bottom-color: rgba(188, 189, 197, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-teal {
    background-color: #2cddbe;
    border-color: rgba(32, 199, 170, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-teal .portlet-header, .portlet-box.skinFull-dark.portlet-teal .portlet-body {
      background-color: #2cddbe; }
    .portlet-box.skinFull-dark.portlet-teal .portlet-header {
      border-bottom-color: rgba(217, 255, 248, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-info {
    background-color: #86d4f5;
    border-color: rgba(105, 193, 230, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-info .portlet-header, .portlet-box.skinFull-dark.portlet-info .portlet-body {
      background-color: #86d4f5; }
    .portlet-box.skinFull-dark.portlet-info .portlet-header {
      border-bottom-color: rgba(231, 247, 254, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-success {
    background-color: #94df4a;
    border-color: rgba(125, 200, 53, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-success .portlet-header, .portlet-box.skinFull-dark.portlet-success .portlet-body {
      background-color: #94df4a; }
    .portlet-box.skinFull-dark.portlet-success .portlet-header {
      border-bottom-color: rgba(229, 252, 208, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-danger {
    background-color: #fa625e;
    border-color: rgba(227, 66, 61, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-danger .portlet-header, .portlet-box.skinFull-dark.portlet-danger .portlet-body {
      background-color: #fa625e; }
    .portlet-box.skinFull-dark.portlet-danger .portlet-header {
      border-bottom-color: rgba(254, 229, 228, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-warning {
    background-color: #f3d768;
    border-color: rgba(223, 193, 74, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-warning .portlet-header, .portlet-box.skinFull-dark.portlet-warning .portlet-body {
      background-color: #f3d768; }
    .portlet-box.skinFull-dark.portlet-warning .portlet-header {
      border-bottom-color: rgba(254, 248, 226, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-purple {
    background-color: #a95ec7;
    border-color: rgba(143, 63, 175, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-purple .portlet-header, .portlet-box.skinFull-dark.portlet-purple .portlet-body {
      background-color: #a95ec7; }
    .portlet-box.skinFull-dark.portlet-purple .portlet-header {
      border-bottom-color: rgba(244, 218, 255, 0.25) !important; }
  .portlet-box.skinFull-dark.portlet-secondary {
    background-color: #a8adb9;
    border-color: rgba(130, 136, 151, 0.5) !important; }
    .portlet-box.skinFull-dark.portlet-secondary .portlet-header, .portlet-box.skinFull-dark.portlet-secondary .portlet-body {
      background-color: #a8adb9; }
    .portlet-box.skinFull-dark.portlet-secondary .portlet-header {
      border-bottom-color: rgba(223, 224, 227, 0.25) !important; }

.portlet-box .portlet-header .portlet-tools .nav-link {
  padding: .25rem .5rem; }
  .portlet-box .portlet-header .portlet-tools .nav-link.active {
    background-color: #0084ff;
    color: #fff; }

.portlet-box.portlet-fullHeight {
  height: calc(100% - 30px); }

.card-header {
  border-color: #e1eaea;
  background-color: #f3f8fa; }

.note-editor.note-frame.card .modal-dialog {
  margin: 0.5rem !important; }

.note-popover .popover-content, .card-header.note-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.note-editor.note-frame {
  border-color: #e1eaea; }
  .note-editor.note-frame .dropdown-menu {
    padding: 2px; }

.note-editable.card-block p {
  margin-bottom: 0px; }

/**jvector map custom**/
.jvectormap-zoomin {
  top: 0px; }

.jvectormap-zoomout {
  top: 30px; }

.jvectormap-zoomin, .jvectormap-zoomout {
  width: 14px;
  height: 14px;
  line-height: 14px; }

/** profile card **/
.profile-content {
  position: relative;
  padding-top: 5rem !important; }
  .profile-content .profile-cover {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: #fff; }
    .profile-content .profile-cover > * {
      opacity: .25; }

/***bootstrap cards**/
.card {
  border-color: #e1eaea; }

.card-header {
  padding: .5rem 1rem;
  background-color: #fff; }

.note-editor.note-frame.card {
  position: relative;
  display: block !important;
  flex: inherit !important; }

/*
Carousel slider
*/
.carousel-item .carousel-cpation {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

/*
Dialogue card fixed at bottom
*/
.dialogue-card-btn {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1040;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  line-height: 1;
  font-size: 1rem; }
  .dialogue-card-btn > i {
    font-size: 1.5rem;
    transition: all .3s;
    -webkit-transition: all .3s; }
  .dialogue-card-btn.active > i:before {
    content: "\e773"; }

.dialogue-card {
  position: fixed;
  z-index: 1039;
  right: 1rem;
  bottom: 7rem;
  width: 350px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .3s;
  transition: all .3s;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 21px 35px 0 rgba(0, 132, 255, 0.15);
  opacity: 0;
  visibility: hidden;
  padding: 0;
  display: flex;
  max-width: 350px;
  flex: 1 1;
  flex-direction: column; }
  .dialogue-card.is-show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  .dialogue-card .dialogue-header {
    background-color: #0084ff;
    border-radius: 4px;
    height: 60px;
    color: #fff;
    box-shadow: 0px 2px 16px rgba(44, 45, 58, 0.15);
    display: flex;
    flex-direction: row;
    align-items: center; }
    .dialogue-card .dialogue-header .dialogue-title {
      color: #fff;
      font-size: 1rem;
      line-height: 1;
      padding-left: 1rem;
      padding-right: 1rem; }
    .dialogue-card .dialogue-header > * {
      height: 100%;
      align-items: center;
      display: flex; }
      .dialogue-card .dialogue-header > * ul {
        margin: 0;
        padding: 0;
        display: flex;
        height: 100%;
        margin-left: auto;
        padding-right: 1rem; }
        .dialogue-card .dialogue-header > * ul > li {
          display: flex;
          height: 100%;
          align-items: center; }
  .dialogue-card .dialogue-content {
    height: 300px; }
    .dialogue-card .dialogue-content .slimScrollBar {
      width: 5px;
      background: #f2f6f8 !important; }
  .dialogue-card .dialogue-card-footer {
    flex-shrink: 0;
    padding: .5rem 1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }

/*
Template dropdown src
*/
.bootstrap-datetimepicker-widget.dropdown-menu {
  z-index: 5000 !important;
  right: 0 !important;
  top: 100% !important;
  left: auto !important; }

.dropdown-menu.dropdown-menu-lg {
  width: 330px; }

.dropdown-menu.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropdown-menu {
  min-width: 160px;
  padding: .8rem;
  font-size: .85rem;
  color: inherit;
  border: 0px;
  box-shadow: 0 0px 5px 1px rgba(16, 114, 206, 0.13);
  margin-top: 1px;
  border-radius: 5px;
  z-index: 1500;
  color: rgba(24, 35, 89, 0.85); }
  .dropdown-menu .dropdown-divider {
    border-color: #e1eaea;
    margin: 5px 0; }
  .dropdown-menu .dropdown-item {
    font-size: inherit;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    text-transform: capitalize;
    display: block;
    padding: .45rem .9375rem;
    color: #868aa8; }
    .dropdown-menu .dropdown-item > i {
      width: auto;
      font-size: 10px;
      line-height: 1.5;
      margin-right: .5rem;
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      position: relative;
      transition: all .3s;
      -webkit-transition: all .3s;
      margin-top: -2px; }
      .dropdown-menu .dropdown-item > i[class^="icon-"], .dropdown-menu .dropdown-item > i[class*=" icon-"] {
        font-size: 16px;
        line-height: 16px; }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
      background-color: #f3f8fa;
      color: #0084ff; }
  .dropdown-menu .slimScrollBar {
    background-color: #f2f6f8 !important;
    border-radius: 0px !important; }
  .dropdown-menu .list .list-item:hover {
    background-color: #f3f8fa; }
  .dropdown-menu .list .list-item .list-body:after {
    background-color: transparent; }
  .dropdown-menu .list .list-item .list-thumb {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    overflow: hidden; }

.dropdown-menu.emoji-dropdown {
  width: 300px;
  height: 300px; }
  .dropdown-menu.emoji-dropdown table.table-light {
    background-color: #fff; }
    .dropdown-menu.emoji-dropdown table.table-light td {
      text-align: center;
      border-top: 0px;
      vertical-align: middle; }

.dropdown-header-top {
  padding: .75rem .9rem;
  background-color: transparent; }

.dropdown-footer {
  display: block;
  text-align: center;
  padding: .75rem .9rem;
  background-color: #f3f8fa;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid rgba(225, 234, 234, 0.5); }
  .dropdown-footer:hover {
    background-color: #f3f6fc; }
  .dropdown-footer > i {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px; }

.dropdown-title {
  font-weight: 600; }

.tool-item {
  display: block;
  line-height: 1; }
  .tool-item > i {
    line-height: 1.2;
    color: #182359;
    font-size: 24px; }
  .tool-item .tool-heading {
    color: inherit;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.5;
    padding-top: 5px;
    opacity: .6;
    font-size: .8rem; }

.dropdown-toggle:after {
  position: relative;
  -webkit-transition: all .3s;
  transition: all .3s;
  line-height: 10px;
  width: 10px;
  font-size: 12px;
  height: 10px;
  top: 3px;
  border: 0px;
  content: "\f0d7";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  text-align: center; }

li.show .dropdown-toggle:after {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg); }

.dropdown-menu-dark {
  background-color: #2c2d3a;
  border-color: #262734;
  color: #868aa8; }

.dropup .dropdown-toggle:after {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  border: 0px;
  position: relative;
  -webkit-transition: all .3s;
  transition: all .3s;
  line-height: 10px;
  width: 10px;
  font-size: 12px;
  height: 10px;
  top: 3px;
  border: 0px;
  content: "\f0d7";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  text-align: center; }

.show.dropup .dropdown-toggle:after {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg); }

@media (min-width: 992px) {
  .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transform-style: preserve-3d; }
  .show.dropdown-menu {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible; }
  .navbar-light .dropdown-menu.dropdown-menu-lg {
    width: 568px; } }

/*
form custom ui style for assan admin....
*/
.tooltip {
  z-index: 9999; }

.form-control::-webkit-input-placeholder, .note-placeholder {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  color: rgba(155, 164, 174, 0.5);
  font-weight: 400;
  opacity: 1; }

.form-control:-ms-input-placeholder, .form-control::-ms-input-placeholder {
  -ms-transition: opacity .3s;
  transition: opacity .3s;
  color: rgba(155, 164, 174, 0.5);
  font-weight: 400;
  opacity: 1; }

.form-control::-webkit-input-placeholder {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  color: rgba(155, 164, 174, 0.5);
  font-weight: 400;
  opacity: .95; }

.form-control::-moz-placeholder {
  -moz-transition: opacity .3s;
  transition: opacity .3s;
  color: rgba(155, 164, 174, 0.5);
  font-weight: 400;
  opacity: .95; }

.form-control:-ms-input-placeholder {
  -ms-transition: opacity .3s;
  transition: opacity .3s;
  color: rgba(155, 164, 174, 0.5);
  font-weight: 400;
  opacity: .95; }

.form-control::-ms-input-placeholder {
  -ms-transition: opacity .3s;
  transition: opacity .3s;
  color: rgba(155, 164, 174, 0.5);
  font-weight: 400;
  opacity: .95; }

.form-control::placeholder {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  color: rgba(155, 164, 174, 0.5);
  font-weight: 400;
  opacity: .95; }

.form-control.form-control-light::-webkit-input-placeholder {
  color: rgba(243, 248, 250, 0.5); }

.form-control.form-control-light::-moz-placeholder {
  color: rgba(243, 248, 250, 0.5); }

.form-control.form-control-light:-ms-input-placeholder {
  color: rgba(243, 248, 250, 0.5); }

.form-control.form-control-light::-ms-input-placeholder {
  color: rgba(243, 248, 250, 0.5); }

.form-control.form-control-light::placeholder {
  color: rgba(243, 248, 250, 0.5); }

.form-control:focus::-webkit-input-placeholder {
  opacity: .5; }

.form-control:focus:-ms-input-placeholder, .form-control:focus::-ms-input-placeholder {
  opacity: .5; }

.form-control:focus::-webkit-input-placeholder {
  opacity: .5; }

.form-control:focus::-moz-placeholder {
  opacity: .5; }

.form-control:focus:-ms-input-placeholder {
  opacity: .5; }

.form-control:focus::-ms-input-placeholder {
  opacity: .5; }

.form-control:focus::placeholder {
  opacity: .5; }

.form-control, select.form-control {
  border: 1px solid #e1eaea;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  font-size: .8rem;
  padding: .45rem .75rem;
  background-color: rgba(243, 248, 250, 0); }
  .form-control.form-control-bBottom, select.form-control.form-control-bBottom {
    border: 1px solid #f3f8fa;
    border-radius: 0px;
    padding-left: 1rem;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px; }
    .form-control.form-control-bBottom:focus, select.form-control.form-control-bBottom:focus {
      box-shadow: none; }
  .form-control:focus, select.form-control:focus {
    background-color: rgba(243, 248, 250, 0.55);
    border-color: rgba(0, 132, 255, 0.5);
    box-shadow: 0px 0px 6px 1px rgba(0, 132, 255, 0.1); }
  .form-control.disabled, .form-control[disabled], .form-control[readonly], select.form-control.disabled, select.form-control[disabled], select.form-control[readonly] {
    background-color: rgba(243, 248, 250, 0.15); }
  .form-control.error, select.form-control.error {
    border-color: #fa625e;
    background-color: rgba(250, 98, 94, 0.1);
    box-shadow: 0px 0px 5px 2px rgba(250, 98, 94, 0.15); }
  .form-control.valid, select.form-control.valid {
    border-color: #94df4a;
    background-color: rgba(148, 223, 74, 0.1);
    box-shadow: 0px 0px 5px 2px rgba(148, 223, 74, 0.15); }
  .form-control::-webkit-input-placeholder, select.form-control::-webkit-input-placeholder {
    color: rgba(44, 45, 58, 0.6); }
  .form-control::-moz-placeholder, select.form-control::-moz-placeholder {
    color: rgba(44, 45, 58, 0.6); }
  .form-control:-ms-input-placeholder, select.form-control:-ms-input-placeholder {
    color: rgba(44, 45, 58, 0.6); }
  .form-control:-moz-placeholder, select.form-control:-moz-placeholder {
    color: rgba(44, 45, 58, 0.6); }

.dropzone {
  border-color: #e1eaea; }

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .75rem 1rem;
  font-size: 1rem; }

select.form-control-sm, .form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .15rem .5rem;
  font-size: .7rem; }

.input-group-text {
  border-color: #e1eaea; }

.search-form-o {
  position: relative; }
  .search-form-o button[type="submit"], .search-form-o button[type="button"], .search-form-o input[type="submit"], .search-form-o input[type="button"] {
    position: absolute;
    right: 10px;
    top: 2px;
    height: 100%;
    font-size: 16px;
    opacity: .6; }

.form-control-light {
  background-color: rgba(16, 114, 206, 0.05);
  color: #fff;
  border-color: rgba(229, 242, 255, 0.15); }
  .form-control-light:focus {
    background-color: rgba(229, 242, 255, 0.12);
    border-color: rgba(229, 242, 255, 0.75);
    color: #fff; }

.form-control.form-control-light::-webkit-input-placeholder {
  color: rgba(243, 248, 250, 0.5); }

select.form-control:not([size]):not([multiple]) {
  height: auto !important; }

label.error, label.valid {
  display: block;
  clear: both;
  width: 100%;
  padding-top: 10px; }
  label.error:before, label.error:after, label.valid:before, label.valid:after {
    content: "";
    display: table;
    clear: both;
    width: 100%; }

label.error, .invalid-feedback {
  color: #fa625e;
  font-size: 90%; }

label.valid, .valid-feedback {
  color: #94df4a;
  font-size: 90%; }

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: #fa625e; }

.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
  border-color: #94df4a; }

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
  border-color: #94df4a;
  background-color: rgba(148, 223, 74, 0.1);
  box-shadow: 0px 0px 5px 2px rgba(148, 223, 74, 0.15); }

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #fa625e;
  background-color: rgba(250, 98, 94, 0.1);
  box-shadow: 0px 0px 5px 2px rgba(250, 98, 94, 0.15); }

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #fa625e; }

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #94df4a; }

.custom-file-label, .custom-file-label::after {
  padding: .6rem .75rem; }

/**input groups**/
.input-group-text {
  background-color: #f3f8fa;
  border-color: #f2f6f8;
  color: #9ba4ae;
  font-size: .8rem; }

.input-icon-group {
  margin-bottom: 1rem; }
  .input-icon-group:after {
    content: "";
    display: table;
    clear: both;
    width: 100%; }
  .input-icon-group .input-icon-append {
    position: relative;
    display: block; }
    .input-icon-group .input-icon-append i {
      position: relative;
      float: left;
      border: 1px solid #e1eaea;
      border-right: 0px;
      width: 2.5rem;
      float: left;
      height: 35px;
      display: block;
      right: -1px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: .85rem;
      line-height: 35px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
      .input-icon-group .input-icon-append i[class^="icon-"], .input-icon-group .input-icon-append i[class*=" icon-"] {
        font-size: 16px; }
      .input-icon-group .input-icon-append i:before, .input-icon-group .input-icon-append i:after {
        opacity: .3; }
    .input-icon-group .input-icon-append .form-control {
      float: right;
      width: calc(100% - 2.5rem);
      position: relative;
      height: 35px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      font-size: .8rem; }
  .input-icon-group.input-icon-right .input-icon-append i {
    float: right;
    right: 1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #e1eaea;
    border-left: 0px; }
  .input-icon-group.input-icon-right .input-icon-append .form-control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    float: left; }
  .input-icon-group.input-icon-sm .input-icon-append .form-control {
    height: 28px;
    font-size: .7rem;
    width: calc(100% - 2rem); }
  .input-icon-group.input-icon-sm .input-icon-append i {
    font-size: .75rem;
    width: 2rem;
    height: 28px;
    line-height: 28px; }
    .input-icon-group.input-icon-sm .input-icon-append i[class^="icon-"], .input-icon-group.input-icon-sm .input-icon-append i[class*=" icon-"] {
      font-size: 16px; }
  .input-icon-group.input-icon-lg .input-icon-append .form-control {
    height: 44px;
    font-size: 1rem;
    width: calc(100% - 3rem); }
  .input-icon-group.input-icon-lg .input-icon-append i {
    font-size: 18px;
    width: 3rem;
    height: 44px;
    line-height: 44px; }
    .input-icon-group.input-icon-lg .input-icon-append i[class^="icon-"], .input-icon-group.input-icon-lg .input-icon-append i[class*=" icon-"] {
      font-size: 24px; }

.input-icon-group.input-icon-dark .input-icon-append i {
  border-color: rgba(229, 242, 255, 0.15);
  border-right-color: rgba(229, 242, 255, 0.15);
  color: #868aa8;
  background-color: rgba(16, 114, 206, 0.05); }

/***********checkboxes***/
.customUi-checkbox, .customUi-radio, .customUi-switchToggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;
  display: inline-block;
  vertical-align: middle; }
  .customUi-checkbox label, .customUi-radio label, .customUi-switchToggle label {
    display: block !important;
    cursor: pointer; }
    .customUi-checkbox label span.label-helper, .customUi-radio label span.label-helper, .customUi-switchToggle label span.label-helper {
      line-height: inherit;
      display: inline-block;
      margin-left: .5rem;
      vertical-align: middle; }
  .customUi-checkbox input, .customUi-radio input, .customUi-switchToggle input {
    opacity: 0 !important;
    height: 20px !important;
    width: 20px !important;
    position: absolute !important; }

.customUi-checkbox {
  padding: 0; }
  .customUi-checkbox label .label-checkbox {
    background: none;
    border: 2px solid #f2f6f8;
    text-align: center;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: #fff;
    margin: 0px;
    display: inline-block;
    vertical-align: middle;
    transition: all .3s;
    -webkit-transition: all .3s; }
    .customUi-checkbox label .label-checkbox:before {
      content: '';
      left: 0;
      border-radius: 2px;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #f3f8fa;
      opacity: 0;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out; }
  .customUi-checkbox input:checked + label .label-checkbox:before, .customUi-checkbox input:checked + label + label .label-checkbox:before {
    animation: pulse .45s ease forwards;
    -webkit-animation: pulse .45s ease forwards; }
  .customUi-checkbox input:checked + label .label-checkbox, .customUi-checkbox input:checked + label + label .label-checkbox {
    border-color: #f2f6f8;
    background: #f2f6f8; }
  .customUi-checkbox input:checked + label .label-checkbox:after, .customUi-checkbox input:checked + label + label .label-checkbox:after {
    display: table;
    width: 6px;
    height: 10px;
    border: 2px solid;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #9ba4ae;
    position: absolute;
    left: 5px;
    text-align: center;
    top: 2px;
    content: "";
    text-align: center; }

.customUi-checkbox.checkboxUi-primary label .label-checkbox {
  border-color: #e5f2ff; }
  .customUi-checkbox.checkboxUi-primary label .label-checkbox:before {
    border-color: #0084ff; }

.customUi-checkbox.checkboxUi-primary input:checked + label .label-checkbox {
  border-color: #0084ff;
  background: #0084ff; }

.customUi-checkbox.checkboxUi-primary input:checked + label .label-checkbox:after {
  color: #e5f2ff; }

.customUi-checkbox.checkboxUi-success label .label-checkbox {
  border-color: #e5fcd0; }
  .customUi-checkbox.checkboxUi-success label .label-checkbox:before {
    border-color: #94df4a; }

.customUi-checkbox.checkboxUi-success input:checked + label .label-checkbox {
  border-color: #94df4a;
  background: #94df4a; }

.customUi-checkbox.checkboxUi-success input:checked + label .label-checkbox:after {
  color: #e5fcd0; }

.customUi-checkbox.checkboxUi-danger label .label-checkbox {
  border-color: #fee5e4; }
  .customUi-checkbox.checkboxUi-danger label .label-checkbox:before {
    border-color: #fa625e; }

.customUi-checkbox.checkboxUi-danger input:checked + label .label-checkbox {
  border-color: #fa625e;
  background: #fa625e; }

.customUi-checkbox.checkboxUi-danger input:checked + label .label-checkbox:after {
  color: #fee5e4; }

.customUi-checkbox.checkboxUi-warning label .label-checkbox {
  border-color: #fef8e2; }
  .customUi-checkbox.checkboxUi-warning label .label-checkbox:before {
    border-color: #f3d768; }

.customUi-checkbox.checkboxUi-warning input:checked + label .label-checkbox {
  border-color: #f3d768;
  background: #f3d768; }

.customUi-checkbox.checkboxUi-warning input:checked + label .label-checkbox:after {
  color: #fef8e2; }

.customUi-checkbox.checkboxUi-info label .label-checkbox {
  border-color: #e7f7fe; }
  .customUi-checkbox.checkboxUi-info label .label-checkbox:before {
    border-color: #86d4f5; }

.customUi-checkbox.checkboxUi-info input:checked + label .label-checkbox {
  border-color: #86d4f5;
  background: #86d4f5; }

.customUi-checkbox.checkboxUi-info input:checked + label .label-checkbox:after {
  color: #e7f7fe; }

.customUi-checkbox.checkboxUi-purple label .label-checkbox {
  border-color: #f4daff; }
  .customUi-checkbox.checkboxUi-purple label .label-checkbox:before {
    border-color: #a95ec7; }

.customUi-checkbox.checkboxUi-purple input:checked + label .label-checkbox {
  border-color: #a95ec7;
  background: #a95ec7; }

.customUi-checkbox.checkboxUi-purple input:checked + label .label-checkbox:after {
  color: #f4daff; }

.customUi-checkbox.checkboxUi-teal label .label-checkbox {
  border-color: #d9fff8; }
  .customUi-checkbox.checkboxUi-teal label .label-checkbox:before {
    border-color: #2cddbe; }

.customUi-checkbox.checkboxUi-teal input:checked + label .label-checkbox {
  border-color: #2cddbe;
  background: #2cddbe; }

.customUi-checkbox.checkboxUi-teal input:checked + label .label-checkbox:after {
  color: #d9fff8; }

.customUi-checkbox.checkboxUi-dark label .label-checkbox {
  border-color: #bcbdc5; }
  .customUi-checkbox.checkboxUi-dark label .label-checkbox:before {
    border-color: #2c2d3a; }

.customUi-checkbox.checkboxUi-dark input:checked + label .label-checkbox {
  border-color: #2c2d3a;
  background: #2c2d3a; }

.customUi-checkbox.checkboxUi-dark input:checked + label .label-checkbox:after {
  color: #bcbdc5; }

.customUi-checkbox.checkboxUi-pink label .label-checkbox {
  border-color: #fde6f3; }
  .customUi-checkbox.checkboxUi-pink label .label-checkbox:before {
    border-color: #f77dc2; }

.customUi-checkbox.checkboxUi-pink input:checked + label .label-checkbox {
  border-color: #f77dc2;
  background: #f77dc2; }

.customUi-checkbox.checkboxUi-pink input:checked + label .label-checkbox:after {
  color: #fde6f3; }

.customUi-checkbox input:disabled + label {
  opacity: .6;
  pointer-events: none; }

.customUi-checkbox.checkbox-square label .label-checkbox {
  border-radius: 0px; }
  .customUi-checkbox.checkbox-square label .label-checkbox:before {
    border-radius: 0px; }

.customUi-checkbox.checkbox-rounded label .label-checkbox {
  border-radius: 100px; }
  .customUi-checkbox.checkbox-rounded label .label-checkbox:before {
    border-radius: 100px; }

.customUi-radio {
  border-radius: 100px;
  -webkit-border-radius: 100px; }
  .customUi-radio label .label-radio {
    background: none;
    border: 2px solid #f2f6f8;
    text-align: center;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    background: transparent;
    margin: 0px;
    display: inline-block;
    vertical-align: middle;
    transition: all .3s;
    -webkit-transition: all .3s; }
    .customUi-radio label .label-radio:before {
      content: '';
      left: 0;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      border: 1px solid #f2f6f8;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out; }
  .customUi-radio input:checked + label .label-radio:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    font-size: 9px;
    position: absolute;
    background-color: #a8adb9; }
  .customUi-radio input:checked + label .label-radio:before {
    animation: pulse .45s ease forwards;
    -webkit-animation: pulse .45s ease forwards; }
  .customUi-radio.radio-square label .label-radio, .customUi-radio.radio-square label .label-radio:before, .customUi-radio.radio-square input:checked + label .label-radio:after {
    border-radius: 0px;
    -webkit-border-radius: 0px; }
  .customUi-radio.radio-radius label .label-radio, .customUi-radio.radio-radius label .label-radio:before, .customUi-radio.radio-radius input:checked + label .label-radio:after {
    border-radius: 3px;
    -webkit-border-radius: 3px; }
  .customUi-radio input:disabled + label {
    opacity: .5;
    pointer-events: none; }
  .customUi-radio.radioUi-primary label .label-radio {
    border-color: #e5f2ff; }
    .customUi-radio.radioUi-primary label .label-radio:before, .customUi-radio.radioUi-primary label .label-radio:after {
      border-color: #0084ff; }
  .customUi-radio.radioUi-primary input:checked + label .label-radio:after {
    background-color: #0084ff; }
  .customUi-radio.radioUi-info label .label-radio {
    border-color: #e7f7fe; }
    .customUi-radio.radioUi-info label .label-radio:before, .customUi-radio.radioUi-info label .label-radio:after {
      border-color: #86d4f5; }
  .customUi-radio.radioUi-info input:checked + label .label-radio:after {
    background-color: #86d4f5; }
  .customUi-radio.radioUi-danger label .label-radio {
    border-color: #fee5e4; }
    .customUi-radio.radioUi-danger label .label-radio:before, .customUi-radio.radioUi-danger label .label-radio:after {
      border-color: #fa625e; }
  .customUi-radio.radioUi-danger input:checked + label .label-radio:after {
    background-color: #fa625e; }
  .customUi-radio.radioUi-success label .label-radio {
    border-color: #e5fcd0; }
    .customUi-radio.radioUi-success label .label-radio:before, .customUi-radio.radioUi-success label .label-radio:after {
      border-color: #94df4a; }
  .customUi-radio.radioUi-success input:checked + label .label-radio:after {
    background-color: #94df4a; }
  .customUi-radio.radioUi-warning label .label-radio {
    border-color: #fef8e2; }
    .customUi-radio.radioUi-warning label .label-radio:before, .customUi-radio.radioUi-warning label .label-radio:after {
      border-color: #f3d768; }
  .customUi-radio.radioUi-warning input:checked + label .label-radio:after {
    background-color: #f3d768; }
  .customUi-radio.radioUi-teal label .label-radio {
    border-color: #d9fff8; }
    .customUi-radio.radioUi-teal label .label-radio:before, .customUi-radio.radioUi-teal label .label-radio:after {
      border-color: #2cddbe; }
  .customUi-radio.radioUi-teal input:checked + label .label-radio:after {
    background-color: #2cddbe; }
  .customUi-radio.radioUi-purple label .label-radio {
    border-color: #f4daff; }
    .customUi-radio.radioUi-purple label .label-radio:before, .customUi-radio.radioUi-purple label .label-radio:after {
      border-color: #a95ec7; }
  .customUi-radio.radioUi-purple input:checked + label .label-radio:after {
    background-color: #a95ec7; }
  .customUi-radio.radioUi-pink label .label-radio {
    border-color: #fde6f3; }
    .customUi-radio.radioUi-pink label .label-radio:before, .customUi-radio.radioUi-pink label .label-radio:after {
      border-color: #f77dc2; }
  .customUi-radio.radioUi-pink input:checked + label .label-radio:after {
    background-color: #f77dc2; }
  .customUi-radio.radioUi-dark label .label-radio {
    border-color: #bcbdc5; }
    .customUi-radio.radioUi-dark label .label-radio:before, .customUi-radio.radioUi-dark label .label-radio:after {
      border-color: #2c2d3a; }
  .customUi-radio.radioUi-dark input:checked + label .label-radio:after {
    background-color: #2c2d3a; }

/**switch toggler**/
.customUi-switchToggle label .label-switchToggle {
  width: 36px;
  height: 18px;
  border-radius: 100px;
  background-color: #f3f8fa;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transition: all .3s;
  -webkit-transition: all .3s; }
  .customUi-switchToggle label .label-switchToggle:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 4px;
    top: 4px;
    background-color: #edf4f7;
    transition: all .3s;
    -webkit-transition: all .3s;
    border-radius: 100px; }

.customUi-switchToggle input:checked + label .label-switchToggle {
  background-color: #a8adb9; }
  .customUi-switchToggle input:checked + label .label-switchToggle:after {
    transform: translateX(18px);
    -webkit-transform: translateX(18px);
    background-color: #fff; }

.customUi-switchToggle.switchToggle-primary label .label-switchToggle {
  background-color: #e5f2ff; }
  .customUi-switchToggle.switchToggle-primary label .label-switchToggle:after {
    background-color: #0084ff; }

.customUi-switchToggle.switchToggle-primary input:checked + label .label-switchToggle {
  background-color: #0084ff; }
  .customUi-switchToggle.switchToggle-primary input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-danger label .label-switchToggle {
  background-color: #fee5e4; }
  .customUi-switchToggle.switchToggle-danger label .label-switchToggle:after {
    background-color: #fa625e; }

.customUi-switchToggle.switchToggle-danger input:checked + label .label-switchToggle {
  background-color: #fa625e; }
  .customUi-switchToggle.switchToggle-danger input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-success label .label-switchToggle {
  background-color: #e5fcd0; }
  .customUi-switchToggle.switchToggle-success label .label-switchToggle:after {
    background-color: #94df4a; }

.customUi-switchToggle.switchToggle-success input:checked + label .label-switchToggle {
  background-color: #94df4a; }
  .customUi-switchToggle.switchToggle-success input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-info label .label-switchToggle {
  background-color: #e7f7fe; }
  .customUi-switchToggle.switchToggle-info label .label-switchToggle:after {
    background-color: #86d4f5; }

.customUi-switchToggle.switchToggle-info input:checked + label .label-switchToggle {
  background-color: #86d4f5; }
  .customUi-switchToggle.switchToggle-info input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-warning label .label-switchToggle {
  background-color: #fef8e2; }
  .customUi-switchToggle.switchToggle-warning label .label-switchToggle:after {
    background-color: #f3d768; }

.customUi-switchToggle.switchToggle-warning input:checked + label .label-switchToggle {
  background-color: #f3d768; }
  .customUi-switchToggle.switchToggle-warning input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-teal label .label-switchToggle {
  background-color: #d9fff8; }
  .customUi-switchToggle.switchToggle-teal label .label-switchToggle:after {
    background-color: #2cddbe; }

.customUi-switchToggle.switchToggle-teal input:checked + label .label-switchToggle {
  background-color: #2cddbe; }
  .customUi-switchToggle.switchToggle-teal input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-purple label .label-switchToggle {
  background-color: #f4daff; }
  .customUi-switchToggle.switchToggle-purple label .label-switchToggle:after {
    background-color: #a95ec7; }

.customUi-switchToggle.switchToggle-purple input:checked + label .label-switchToggle {
  background-color: #a95ec7; }
  .customUi-switchToggle.switchToggle-purple input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-pink label .label-switchToggle {
  background-color: #fde6f3; }
  .customUi-switchToggle.switchToggle-pink label .label-switchToggle:after {
    background-color: #f77dc2; }

.customUi-switchToggle.switchToggle-pink input:checked + label .label-switchToggle {
  background-color: #f77dc2; }
  .customUi-switchToggle.switchToggle-pink input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle.switchToggle-dark label .label-switchToggle {
  background-color: #bcbdc5; }
  .customUi-switchToggle.switchToggle-dark label .label-switchToggle:after {
    background-color: #2c2d3a; }

.customUi-switchToggle.switchToggle-dark input:checked + label .label-switchToggle {
  background-color: #2c2d3a; }
  .customUi-switchToggle.switchToggle-dark input:checked + label .label-switchToggle:after {
    background-color: #fff; }

.customUi-switchToggle input:disabled + label {
  opacity: .5;
  pointer-events: none; }

.customUi-switchToggle.switchToggle-square label .label-switchToggle {
  border-radius: 0px; }
  .customUi-switchToggle.switchToggle-square label .label-switchToggle:after {
    border-radius: 0px; }

.customUi-switchToggle.switchToggle-lg label .label-switchToggle {
  width: 50px;
  height: 24px; }
  .customUi-switchToggle.switchToggle-lg label .label-switchToggle:after {
    width: 12px;
    height: 12px;
    top: 6px;
    left: 6px; }

.customUi-switchToggle.switchToggle-lg input:checked + label .label-switchToggle:after {
  -webkit-transform: translateX(26px);
          transform: translateX(26px); }

.customUi-switchToggle.switchToggle-air label .label-switchToggle {
  width: 42px;
  height: 14px; }
  .customUi-switchToggle.switchToggle-air label .label-switchToggle:after {
    width: 20px;
    height: 20px;
    left: 0px;
    top: -3px;
    box-shadow: 0px 0px 2px 1px rgba(44, 45, 58, 0.15); }

.customUi-switchToggle.switchToggle-air input:checked + label .label-switchToggle:after {
  -webkit-transform: translateX(22px);
          transform: translateX(22px); }

.customUi-switchToggle.switchToggle-air.switchToggle-lg label .label-switchToggle {
  width: 52px;
  height: 24px; }
  .customUi-switchToggle.switchToggle-air.switchToggle-lg label .label-switchToggle:after {
    width: 28px;
    height: 28px;
    left: 0px;
    top: -2px; }

.customUi-switchToggle.switchToggle-air.switchToggle-lg input:checked + label .label-switchToggle:after {
  -webkit-transform: translateX(26px);
          transform: translateX(26px); }

.check-todo.customUi-checkbox {
  display: block;
  width: 100%;
  padding-left: 2.5rem;
  position: relative; }
  .check-todo.customUi-checkbox input {
    left: 0;
    top: 4px; }
  .check-todo.customUi-checkbox label .label-helper {
    margin-left: 0px; }
  .check-todo.customUi-checkbox input:checked + label > * {
    text-decoration: line-through;
    opacity: .6; }
  .check-todo.customUi-checkbox label .label-checkbox {
    position: absolute;
    width: 20px;
    left: 0;
    top: 4px; }

.dz-default.dz-message span {
  opacity: .6; }

/*
Page header
*/
header.page-header {
  position: relative;
  width: 100%;
  z-index: 500;
  padding-top: 0px;
  padding-bottom: 0px; }
  header.page-header .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }
    header.page-header .dropdown-menu.dropdown-menu-right {
      right: 0 !important;
      left: auto !important; }
  header.page-header .nav {
    align-items: center; }
    header.page-header .nav li:not(.list-item) {
      min-height: 3.5rem;
      align-items: center !important;
      justify-content: center !important; }
      header.page-header .nav li:not(.list-item) > a {
        align-items: center !important;
        justify-content: center !important;
        display: flex;
        line-height: 1; }
    header.page-header .nav .user-dropdown a .img-fluid {
      width: 35px; }
    header.page-header .nav .user-dropdown a.nav-link {
      position: relative; }
  header.page-header .nav-link {
    position: relative;
    font-weight: 700;
    text-transform: capitalize;
    font-size: .85rem;
    color: inherit; }
    header.page-header .nav-link:hover {
      opacity: 1; }
    header.page-header .nav-link > i {
      font-size: 24px; }
    header.page-header .nav-link .badge {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 18px;
      height: 18px;
      display: inline-flex !important;
      line-height: 1;
      align-items: center !important;
      justify-content: center !important;
      font-size: .65rem; }
      header.page-header .nav-link .badge:empty {
        width: 8px;
        height: 8px;
        top: 9px;
        right: 13px; }
    header.page-header .nav-link .badge.anibadge:after {
      border-radius: 50%; }
  header.page-header li.nav-item {
    display: flex;
    align-items: center !important; }
  header.page-header .form-inline {
    width: 210px;
    border: 0px;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    background-color: transparent; }
    header.page-header .form-inline .form-control {
      width: 100%;
      border-radius: 0;
      font-size: 14px;
      height: 40px;
      padding-left: 30px;
      background-color: transparent;
      position: relative; }
    header.page-header .form-inline button {
      text-align: left;
      position: absolute;
      left: 0;
      top: 12px;
      line-height: 1;
      border: 0px !important;
      font-size: 1rem; }
  header.page-header .sidenav-btn {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
    margin-left: .5rem; }
    header.page-header .sidenav-btn .navbar-toggler-icon {
      position: absolute;
      width: 20px;
      height: 2px;
      -webkit-transition-timing-function: ease;
              transition-timing-function: ease;
      -webkit-transition-duration: .15s;
              transition-duration: .15s;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      border-radius: 4px;
      background-color: #9ba4ae;
      top: 50%;
      display: block;
      margin-top: -1px; }
      header.page-header .sidenav-btn .navbar-toggler-icon:after {
        bottom: -6px; }
      header.page-header .sidenav-btn .navbar-toggler-icon:before {
        top: -6px; }
      header.page-header .sidenav-btn .navbar-toggler-icon:before, header.page-header .sidenav-btn .navbar-toggler-icon:after {
        display: block;
        content: "";
        position: absolute;
        width: 12px;
        height: 2px;
        -webkit-transition-timing-function: ease;
                transition-timing-function: ease;
        -webkit-transition-duration: .15s;
                transition-duration: .15s;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        border-radius: 4px;
        background-color: #9ba4ae; }
    header.page-header .sidenav-btn.toggler-folded .navbar-toggler-icon:before {
      -webkit-transform: translate3d(8px, 0, 0);
              transform: translate3d(8px, 0, 0); }
    header.page-header .sidenav-btn.toggler-folded .navbar-toggler-icon:after {
      -webkit-transform: translate3d(8px, 0, 0);
              transform: translate3d(8px, 0, 0); }

/*****navbar-toggler-icon****/
.page-header.darkHeader {
  background-color: #2c2d3a; }
  .page-header.darkHeader .nav-link, .page-header.darkHeader button {
    color: #868aa8; }
    .page-header.darkHeader .nav-link:hover, .page-header.darkHeader button:hover {
      color: #868aa8; }
  .page-header.darkHeader .search-collapse .form-inline {
    background-color: transparent; }
    .page-header.darkHeader .search-collapse .form-inline .form-control {
      background-color: transparent;
      color: rgba(255, 255, 255, 0.75);
      border-color: rgba(229, 242, 255, 0.5); }
  .page-header.darkHeader input::-webkit-input-placeholder {
    color: rgba(243, 248, 250, 0.85); }
  .page-header.darkHeader input::-moz-placeholder {
    color: rgba(243, 248, 250, 0.85); }
  .page-header.darkHeader input:-ms-input-placeholder {
    color: rgba(243, 248, 250, 0.85); }
  .page-header.darkHeader input:-moz-placeholder {
    color: rgba(243, 248, 250, 0.85); }
  .page-header.darkHeader .nav-icons > .nav-item:not(.user-dropdown) > .nav-link > i {
    font-size: 22px;
    position: relative;
    top: 1px; }
  .page-header.darkHeader.page-header .sidenav-btn .navbar-toggler-icon {
    background-color: #868aa8; }
    .page-header.darkHeader.page-header .sidenav-btn .navbar-toggler-icon:before, .page-header.darkHeader.page-header .sidenav-btn .navbar-toggler-icon:after {
      background-color: #868aa8; }

.page-header.whiteHeader {
  border-bottom: 1px solid #e1eaea;
  background-color: white; }
  .page-header.whiteHeader .search-collapse .form-inline .form-control {
    color: inherit; }
  .page-header.whiteHeader .search-collapse .form-inline button {
    background-color: transparent; }
  .page-header.whiteHeader .nav-link, .page-header.whiteHeader button {
    color: inherit;
    opacity: .75; }
  .page-header.whiteHeader.page-header .sidenav-btn .navbar-toggler-icon {
    background-color: rgba(24, 35, 89, 0.85); }
    .page-header.whiteHeader.page-header .sidenav-btn .navbar-toggler-icon:before, .page-header.whiteHeader.page-header .sidenav-btn .navbar-toggler-icon:after {
      background-color: rgba(24, 35, 89, 0.85); }
  .page-header.whiteHeader .navbar-collapse.search-collapse {
    background-color: transparent; }
  .page-header.whiteHeader input::-webkit-input-placeholder {
    color: rgba(155, 164, 174, 0.57); }
  .page-header.whiteHeader input::-moz-placeholder {
    color: rgba(155, 164, 174, 0.57); }
  .page-header.whiteHeader input:-ms-input-placeholder {
    color: rgba(155, 164, 174, 0.57); }
  .page-header.whiteHeader input:-moz-placeholder {
    color: rgba(155, 164, 174, 0.57); }

.navbar-search {
  position: relative;
  width: 220px;
  height: 3.5rem; }
  .navbar-search .form-control:focus {
    background-color: transparent; }

@media (min-width: 992px) {
  .collapse.search-collapse {
    margin-left: 1rem; } }

/* 
Horizontal
*/
.layout-horizontal header.page-header .dropdown-menu {
  margin-top: 0px; }

.navbar.navbar-horizontal {
  min-height: auto;
  height: auto;
  max-height: 100%;
  padding: 0px 1rem;
  position: relative;
  z-index: 400;
  box-shadow: 0px 2px 10px rgba(44, 45, 58, 0.1); }
  .navbar.navbar-horizontal .container {
    position: relative; }
  .navbar.navbar-horizontal .dropdown-menu {
    margin-top: 0px; }
  .navbar.navbar-horizontal .navbar-nav > li {
    padding: .5rem .15rem; }
  .navbar.navbar-horizontal .navbar-nav > li > .nav-link {
    border-radius: 4px;
    padding: .5rem 1rem;
    font-size: .85rem;
    font-weight: 400;
    color: #868aa8; }
    .navbar.navbar-horizontal .navbar-nav > li > .nav-link > i {
      font-size: 13px;
      text-align: center;
      display: inline-block;
      margin-right: .5rem;
      position: relative;
      vertical-align: middle;
      line-height: inherit; }

.layout-horizontal {
  /**multi level dropdown menu**/ }
  .layout-horizontal .dropdown-menu {
    min-width: 200px;
    padding: 10px 0px; }
    .layout-horizontal .dropdown-menu .dropdown-item {
      border-radius: 0px;
      padding: .5rem 1rem; }
    .layout-horizontal .dropdown-menu.dropdown-menu-right .dropdown-submenu .dropdown-menu {
      left: auto;
      margin: 0px;
      top: 0;
      right: 100%;
      margin-right: 0;
      border-radius: 4px; }
  .layout-horizontal .dropdown-submenu {
    position: relative; }
  .layout-horizontal .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%; }
  .layout-horizontal .dropdown-submenu .dropdown-toggle {
    position: relative; }
  .layout-horizontal .dropdown-submenu .dropdown-toggle:after {
    content: "\f0d7";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    transform: rotate(-90deg) !important;
    -webkit-transform: rotate(-90deg) !important;
    position: absolute;
    right: .5rem;
    top: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    text-align: center; }
  .layout-horizontal .dropdown-menu-right .dropdown-submenu .dropdown-toggle:after {
    transform: rotate(90deg) !important;
    -webkit-transform: rotate(90deg) !important; }
  .layout-horizontal .navbar-light .search-horizontal {
    width: 220px;
    right: 30px;
    top: 50%;
    margin-top: -15px;
    height: 30px;
    position: absolute; }
  .layout-horizontal .navbar-light form .form-control {
    width: 220px;
    height: 100%;
    position: relative; }
    .layout-horizontal .navbar-light form .form-control:focus {
      background-color: transparent;
      box-shadow: none; }
  .layout-horizontal .navbar-light form button {
    position: absolute;
    right: 0;
    height: 100%;
    top: .15rem; }
  .layout-horizontal .navbar-brand {
    margin-left: 1rem; }

@media (min-width: 992px) {
  .navbar-light.navbar-horizontal .navbar-nav .active > .nav-link, .navbar-light.navbar-horizontal .navbar-nav .nav-link.active, .navbar-light.navbar-horizontal .navbar-nav .nav-link.show, .navbar-light.navbar-horizontal .navbar-nav .show > .nav-link {
    background-color: #0084ff;
    color: #fff; } }

/*
Layout aside mini
*/
.aside-icon-o {
  width: 4.5rem;
  min-height: 100vh;
  flex-shrink: 0;
  z-index: 600;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  /* .app-logo{
        width: 100%;
        overflow: hidden;
        position: relative;
        min-height: 3.5rem;
        background-color: $warning-light;
        border-bottom: 1px solid rgba($primary-active,.05);
        >i{
            font-size: 1.25rem;
            color: $primary;
        }
    } */ }
  .aside-icon-o .sidenav-mini {
    width: 4.5rem;
    border: inherit;
    margin: 0;
    pointer-events: inherit;
    display: flex;
    flex-direction: column;
    height: 100%; }

.aside-mini-content {
  padding: 1.5rem 0; }
  .aside-mini-content .nav .nav-item {
    display: block;
    width: 100%;
    position: relative; }
    .aside-mini-content .nav .nav-item .nav-link {
      display: block;
      flex-direction: row;
      padding: 0px;
      width: 100%;
      padding: .5rem 1.25rem;
      color: inherit; }
      .aside-mini-content .nav .nav-item .nav-link .badge {
        position: absolute;
        right: .5rem;
        top: .5rem;
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: .6rem;
        font-weight: 600; }
      .aside-mini-content .nav .nav-item .nav-link:after {
        display: table;
        content: "";
        clear: both; }
    .aside-mini-content .nav .nav-item .nav-link > i {
      text-align: center;
      padding: 0px;
      display: flex;
      width: 2rem;
      height: 2rem;
      line-height: 1;
      font-size: 14px;
      align-items: center !important;
      justify-content: center !important;
      border-radius: 10rem;
      transition: all .2s;
      -webkit-transition: all .2s; }
    .aside-mini-content .nav .nav-item .nav-link .nav-text {
      display: block;
      float: right;
      line-height: 2rem; }
  .aside-mini-content .nav .dropdown-menu {
    width: 250px;
    border: 0px;
    top: calc(100% - 3.5rem) !important;
    left: 100% !important;
    margin-left: .75rem;
    box-shadow: 0px 0px 10px rgba(0, 132, 255, 0.12);
    -webkit-box-shadow: 0px 0px 50px rgba(0, 132, 255, 0.12); }
    .aside-mini-content .nav .dropdown-menu:before, .aside-mini-content .nav .dropdown-menu:after {
      right: 100%;
      top: 2rem;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .aside-mini-content .nav .dropdown-menu:after {
      border-color: rgba(136, 183, 213, 0);
      border-right-color: #fff;
      border-width: 10px;
      margin-top: -10px; }
    .aside-mini-content .nav .dropdown-menu:before {
      border-color: rgba(194, 225, 245, 0);
      border-right-color: #fff;
      border-width: 11px;
      margin-top: -11px; }
  .aside-mini-content .nav .dropup .dropdown-menu {
    top: auto !important;
    bottom: calc(100% - 4rem) !important; }
    .aside-mini-content .nav .dropup .dropdown-menu:before, .aside-mini-content .nav .dropup .dropdown-menu:after {
      bottom: 1.5rem;
      top: auto; }
  .aside-mini-content .nav .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -webkit-transform: translateY(10px) !important;
    transform: translateY(10px) !important;
    -webkit-transform-style: preserve-3d; }
  .aside-mini-content .nav .show.dropdown-menu {
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
    opacity: 1;
    visibility: visible; }
  .aside-mini-content .dropup.dropdown-submenu .dropdown-menu {
    display: none; }
    .aside-mini-content .dropup.dropdown-submenu .dropdown-menu:before, .aside-mini-content .dropup.dropdown-submenu .dropdown-menu:after {
      bottom: 2rem; }

.aside-icon-o.aside-light {
  background-color: #fff;
  box-shadow: 1px -10px 10px rgba(44, 45, 58, 0.12);
  -webkit-box-shadow: 1px -10px 10px rgba(44, 45, 58, 0.12); }
  .aside-icon-o.aside-light .aside-mini-content .nav .nav-item .nav-link > i {
    background-color: rgba(229, 242, 255, 0.2);
    color: rgba(0, 132, 255, 0.55); }
  .aside-icon-o.aside-light .aside-mini-content .nav .nav-item.active a.nav-link > i, .aside-icon-o.aside-light .aside-mini-content .nav .nav-item.show a.nav-link > i, .aside-icon-o.aside-light .aside-mini-content .nav .nav-item:hover a.nav-link > i {
    background: -webkit-gradient(linear, left top, left bottom, from(#86d4f5), to(#0084ff));
    background: linear-gradient(180deg, #86d4f5, #0084ff);
    background: -webkit-linear-gradient(180deg, #86d4f5, #0084ff);
    color: #fff; }

.aside-icon-o.aside-dark {
  background-color: #1072ce;
  box-shadow: 1px -10px 10px rgba(44, 45, 58, 0.12);
  -webkit-box-shadow: 1px -10px 10px rgba(44, 45, 58, 0.12);
  color: #fff; }
  .aside-icon-o.aside-dark .aside-mini-content .nav .nav-item {
    color: rgba(243, 248, 250, 0.5); }
    .aside-icon-o.aside-dark .aside-mini-content .nav .nav-item .nav-link > i {
      background-color: rgba(0, 132, 255, 0.52);
      color: rgba(255, 255, 255, 0.75); }
    .aside-icon-o.aside-dark .aside-mini-content .nav .nav-item.active a.nav-link > i, .aside-icon-o.aside-dark .aside-mini-content .nav .nav-item.show a.nav-link > i, .aside-icon-o.aside-dark .aside-mini-content .nav .nav-item:hover a.nav-link > i {
      background-color: #0084ff;
      color: #fff; }
  .aside-icon-o.aside-dark .app-logo > i {
    color: #fff; }

@media (min-width: 768px) {
  .aside-icon-o .aside-mini-content .nav .nav-item .nav-link .nav-text {
    display: none; } }

@media (max-width: 991px) {
  .layout-aside-mini .page-wrapper {
    flex-flow: row !important;
    align-content: start !important; } }

@media (max-width: 767px) {
  .aside-icon-o {
    width: 13rem;
    position: fixed;
    padding-top: 3.5rem;
    left: 0;
    top: 0;
    height: 100%;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out; }
    .aside-icon-o .sidenav-mini {
      width: 13rem;
      height: calc(100% -3.5rem);
      overflow-y: auto;
      /* .app-logo{
                position: fixed;
                left:0;
                top:0;
                    -webkit-box-pack: start!important;
    -ms-flex-pack: start!important;
    justify-content: start!important;
    padding-left: 1.5rem;
            } */ }
    .aside-icon-o .aside-mini-content .nav .dropdown-menu {
      opacity: 1;
      display: none;
      visibility: visible;
      position: static !important;
      left: 0 !important;
      margin: 0px;
      border-radius: 0px;
      border: 0px;
      width: 100% !important; }
      .aside-icon-o .aside-mini-content .nav .dropdown-menu:before, .aside-icon-o .aside-mini-content .nav .dropdown-menu:after {
        display: none; }
    .aside-icon-o .aside-close {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      z-index: 99; }
      .aside-icon-o .aside-close > i {
        font-size: 24px; }
    .aside-icon-o .badge {
      display: none !important; }
    .aside-icon-o .aside-mini-content .nav .show .dropdown-menu {
      display: block; }
    .aside-icon-o.show {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0); } }

/*
main templates layouts
*/
.page-wrapper {
  width: 100%;
  display: flex;
  flex: 1 1; }

.page-aside {
  flex-shrink: 0;
  position: relative;
  z-index: 750;
  opacity: 1;
  min-height: 100vh;
  transition: all .3s;
  -webkit-transition: all .3s;
  width: 240px; }
  .page-aside > * {
    width: inherit;
    margin: 0;
    overflow: hidden;
    height: 100%; }

.sidenav {
  border: inherit;
  margin: 0;
  pointer-events: inherit;
  display: flex;
  flex-direction: column;
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px); }
  .sidenav .flex {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  .sidenav .slimScrollBar {
    background: #e5f2ff !important;
    border-radius: 0px !important;
    width: 5px !important; }

.content {
  flex: 1 1;
  display: flex !important;
  flex-direction: column;
  box-shadow: none;
  transition: all .3s;
  -webkit-transition: all .3s; }

.page-content {
  position: relative; }

.content-footer {
  margin-top: auto;
  min-height: 3.5rem; }
  .content-footer .p-3 {
    padding: 1rem !important; }

.page-aside > * {
  box-shadow: inset -1px 0 0 0 #e1eaea;
  overflow: hidden;
  width: 240px; }

.collapse.in {
  display: block;
  height: auto !important; }

.aside-footer {
  flex-shrink: 0;
  padding: 0 !important;
  padding-left: 1.5rem !important;
  height: 52px;
  line-height: 52px; }

.slimScrollBar {
  background: #edf4f7 !important;
  width: 5px !important;
  border-radius: 0px !important; }

.page-aside .app-logo {
  width: 240px;
  height: 3.5rem;
  max-height: 3.5rem;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  position: relative;
  background-color: #0084ff;
  transition: all .3s;
  -webkit-transition: all .3s; }
  .page-aside .app-logo > i {
    font-size: 18px !important;
    line-height: 40px;
    width: 40px;
    height: 40px;
    background-color: #0084ff;
    background: -webkit-linear-gradient(-90deg, #86d4f5, #0084ff); }
  .page-aside .app-logo .logo-text {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    margin-left: 8px;
    position: relative;
    letter-spacing: .5px; }

.hidden-scroll {
  flex: 1 1;
  width: auto;
  margin-right: -17px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.page-aside.aside-fixed > * {
  position: fixed; }

.page-aside.aside-fixed .hidden-scroll {
  margin-right: -17px; }

.content-footer {
  border-top: 1px solid #e1eaea;
  border-color: #e1eaea !important; }

.navbar {
  min-height: 3.5rem;
  max-height: 3.5rem;
  position: relative; }
  .navbar form button {
    position: absolute;
    right: 1rem;
    top: 1.2rem; }
  .navbar form .form-control {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding-left: 1rem;
    border-radius: 0px; }

/********************
Off-canvas menu
*********************/
.offcanvas-wrapper {
  z-index: 900;
  transition: -webkit-transform .35s ease-in-out;
  -webkit-transition: -webkit-transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
  transition: transform .35s ease-in-out, -webkit-transform .3s ease-in-out;
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0; }
  .offcanvas-wrapper .offcanvas-collapse.offcanvas-menu {
    position: relative;
    z-index: 1000;
    opacity: 1;
    height: auto;
    min-height: 100vh;
    transition: all .3s;
    -webkit-transition: all .3s;
    width: 450px;
    position: absolute;
    right: 0;
    background-color: #fff;
    overflow: hidden;
    bottom: 0;
    top: 0;
    box-shadow: -4px 4px 10px rgba(44, 45, 58, 0.19);
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .offcanvas-wrapper .offcanvas-collapse.offcanvas-menu .close {
      opacity: 1;
      line-height: 1;
      color: inherit;
      top: .85rem !important;
      right: 1rem !important; }
    .offcanvas-wrapper .offcanvas-collapse.offcanvas-menu .flex {
      overflow-y: auto;
      height: 100%;
      -webkit-overflow-scrolling: touch; }
    .offcanvas-wrapper .offcanvas-collapse.offcanvas-menu .offcanvas-content {
      border: inherit;
      margin: 0;
      pointer-events: inherit;
      display: flex;
      flex-direction: column;
      width: inherit;
      margin: 0;
      overflow: hidden;
      height: 100%;
      position: fixed;
      width: 100%; }
  .offcanvas-wrapper .offcanvas-footer {
    flex-shrink: 0; }
  .offcanvas-wrapper .offcanvas-overlay {
    width: 100%;
    z-index: 999;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #2c2d3a;
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
    opacity: 0; }
  .offcanvas-wrapper.show {
    opacity: 1;
    visibility: visible; }
    .offcanvas-wrapper.show .offcanvas-collapse.offcanvas-menu {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    .offcanvas-wrapper.show .offcanvas-overlay {
      opacity: .6; }

@media (min-width: 1200px) {
  body.layout-boxed {
    padding: 2rem 0;
    background-color: #0084ff;
    background: -webkit-gradient(linear, left top, left bottom, from(#e5f2ff), to(#f4daff));
    background: linear-gradient(-180deg, #e5f2ff, #f4daff);
    background: -webkit-linear-gradient(-180deg, #e5f2ff, #f4daff);
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    body.layout-boxed .page-wrapper {
      background-color: #f3f8fa;
      border-radius: 0;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      overflow-x: hidden; } }

body.layout-fixed {
  height: 100%; }
  body.layout-fixed .page-wrapper {
    max-height: 100%;
    height: 100vh;
    flex: 1 1; }
  body.layout-fixed .page-content {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  body.layout-fixed .aside-footer, body.layout-fixed .content-footer {
    display: none; }

@media (min-width: 992px) {
  .page-content, .page-subheader {
    padding-left: 15px;
    padding-right: 15px; }
  .page-aside {
    display: block !important; }
  .page-aside .hidden-scroll {
    flex: 1 1;
    width: auto;
    margin-right: -17px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
  .page-aside.aside-folded {
    width: 60px; }
    .page-aside.aside-folded .app-logo {
      width: 63px;
      padding-left: 0px;
      text-align: center;
      transform: translate(177px, 0);
      -webkit-transform: translate(177px, 0); }
      .page-aside.aside-folded .app-logo .logo-text {
        display: none !important; }
    .page-aside.aside-folded .sidenav {
      transform: translate(-177px, 0px);
      -webkit-transform: translate(-177px, 0px);
      transition: all .3s;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s; }
    .page-aside.aside-folded:hover, .page-aside.aside-folded:focus, .page-aside.aside-folded.active {
      /* .sidenav{
                transform: translate(0px, 0px);
                -webkit-transform: translate(0px, 0px);
                -ms-transform: translate(0px, 0px);
                -moz-transform: translate(0px, 0px);
            } */
      /* .app-logo{
                width: 240px;
                transform: translate(0,0);
                -webkit-transform: translate(0,0);
                .logo-text{
                    display: flex!important;
                }
            } */ }
  .page-header.navbar.navbar-expand-lg {
    padding-top: 0;
    padding-bottom: 0; }
  .offcanvas-collapse.offcanvas-menu {
    width: 568px; } }

/**dark & light nav colors**/
.sidenav .nav-thumbnail, .sidenav .nav-text, .sidenav .has-arrow:after, .sidenav .metisMenu > li ul > li > a {
  transition: all .3s;
  -webkit-transition: all .3s; }

.sidenav .nav-text, .sidenav a.has-arrow:after {
  opacity: .75; }

.sidenav .nav-thumbnail {
  opacity: 1;
  color: inherit; }

.sidenav a:hover {
  color: inherit; }

.sidenav .aside-footer, .sidenav .nav-title {
  opacity: .75; }

.sidenav .aside-footer {
  border-top: 1px solid rgba(225, 234, 234, 0.19);
  height: 3.5rem; }

.sidenav .metisMenu > li:hover:not(.nav-title) > .nav-thumbnail, .sidenav .metisMenu > li:hover:not(.nav-title) .nav-text, .sidenav .metisMenu > li:hover:not(.nav-title) a.has-arrow:after, .sidenav .metisMenu > li.active > .nav-thumbnail, .sidenav .metisMenu > li.active .nav-text, .sidenav .metisMenu > li.active a.has-arrow:after {
  opacity: 1; }

.sidenav .metisMenu > li > ul li > a, .sidenav .metisMenu > li > ul li > .nav-thumbnail {
  opacity: .6; }

.sidenav .metisMenu > li ul > li.active > .nav-thumbnail, .sidenav .metisMenu > li ul > li.active > a, .sidenav .metisMenu > li ul > li:hover > .nav-thumbnail, .sidenav .metisMenu > li ul > li:hover > a {
  opacity: 1; }

.sidenav .metisMenu > li > ul > li, .sidenav .metisMenu li > ul > li > ul {
  background-color: transparent !important; }

.darkNav {
  background: #262734;
  color: #868aa8;
  box-shadow: 2px 2px 15px rgba(188, 189, 197, 0.1) !important;
  border-color: rgba(188, 189, 197, 0.5) !important; }
  .darkNav .metisMenu li:hover:not(.nav-title) > .nav-thumbnail, .darkNav .metisMenu li:hover:not(.nav-title) .nav-text, .darkNav .metisMenu li:hover:not(.nav-title) > a.has-arrow:after, .darkNav .metisMenu li.active > .nav-thumbnail, .darkNav .metisMenu li.active .nav-text, .darkNav .metisMenu li.active > a.has-arrow:after {
    color: #fff; }
  .darkNav .metisMenu li.nav-title, .darkNav .metisMenu li .aside-footer {
    color: #868aa8; }
  .darkNav .metisMenu li .nav-title > .nav-thumbnail {
    background-color: #2c2d3a; }
  .darkNav .metisMenu li > a:after {
    opacity: .75; }
  .darkNav .metisMenu > li.active > .nav-thumbnail {
    background-color: #0084ff;
    background: -webkit-gradient(linear, right top, left top, from(#0084ff), to(#1072ce));
    background: linear-gradient(-90deg, #0084ff, #1072ce);
    background: -webkit-linear-gradient(-90deg, #0084ff, #1072ce); }
  .darkNav .metisMenu > li ul > li.active > .nav-thumbnail, .darkNav .metisMenu > li ul > li.active > a, .darkNav .metisMenu > li ul > li:hover > .nav-thumbnail, .darkNav .metisMenu > li ul > li:hover > a {
    color: #fff; }
  .darkNav .app-logo {
    background-color: rgba(16, 114, 206, 0.1);
    color: #fff; }
    .darkNav .app-logo > i, .darkNav .app-logo .logo-text {
      color: #fff; }

.whiteNav {
  background-color: #fff;
  color: #868aa8; }
  .whiteNav .metisMenu li:hover:not(.nav-title) > a, .whiteNav .metisMenu li:hover:not(.nav-title) > a.has-arrow:after, .whiteNav .metisMenu li.active > a, .whiteNav .metisMenu li.active > a.has-arrow:after {
    color: #0084ff; }
  .whiteNav .metisMenu li.active > .nav-thumbnail {
    background-color: #0084ff;
    background: -webkit-gradient(linear, right top, left top, from(#0084ff), to(#1072ce));
    background: linear-gradient(-90deg, #0084ff, #1072ce);
    background: -webkit-linear-gradient(-90deg, #0084ff, #1072ce);
    color: #fff; }
  .whiteNav .metisMenu li > a:after {
    opacity: .5; }
  .whiteNav .metisMenu > li ul > li.active > a, .whiteNav .metisMenu > li ul > li:hover > a {
    color: #0084ff; }
  .whiteNav .app-logo {
    background-color: transparent;
    border-bottom: 1px solid #f3f8fa; }
    .whiteNav .app-logo .logo-text {
      color: inherit; }
    .whiteNav .app-logo > i {
      color: #fff; }
  .whiteNav .aside-footer {
    border-top: 1px solid #e1eaea; }

/*
Maps style
*/
.gmap-marker {
  background-color: #0084ff;
  position: relative;
  padding: .5rem 1rem;
  text-align: center;
  min-width: 5rem;
  border-radius: 5px;
  box-shadow: 4px 0px 10px 1px rgba(0, 132, 255, 0.2);
  font-size: .875rem;
  color: #e5f2ff; }
  .gmap-marker:before, .gmap-marker:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .gmap-marker:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #0084ff;
    border-width: 11px;
    margin-left: -11px; }
  .gmap-marker:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #0084ff;
    border-width: 5px;
    margin-left: -5px; }

/*
Metis menu
*/
.metisMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 1rem 0; }
  .metisMenu li {
    background: none;
    display: block;
    line-height: 1;
    position: relative;
    clear: right; }
    .metisMenu li:after {
      content: "";
      display: table;
      clear: both;
      width: 100%; }
    .metisMenu li > a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70%;
      display: inline-block;
      font-weight: 400;
      position: relative;
      text-transform: capitalize;
      padding-left: 21px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      clear: both; }
      .metisMenu li > a > .nav-label {
        font-size: 12px;
        opacity: 0.4;
        display: block;
        clear: both;
        line-height: 20px; }
      .metisMenu li > a > .nav-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        line-height: 20px; }
    .metisMenu li .nav-thumbnail {
      float: right;
      position: relative;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      text-transform: capitalize; }
  .metisMenu > li {
    line-height: 1;
    padding-top: .25rem;
    padding-bottom: .15rem; }
    .metisMenu > li ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      list-style: none;
      clear: both; }
      .metisMenu > li ul > li {
        position: relative; }
  .metisMenu > li a.has-arrow:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f054";
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
    display: block;
    width: 10px;
    height: 10px;
    line-height: 10px;
    font-size: 10px;
    text-align: center; }
  .metisMenu > li > .nav-thumbnail {
    width: 36px;
    height: 36px;
    line-height: 36px;
    z-index: 2;
    right: 14px;
    top: 0px;
    font-size: 18px;
    text-align: center; }
  .metisMenu > li > a {
    font-size: .95rem;
    font-weight: 400;
    line-height: 36px; }
  .metisMenu li.active > a.has-arrow:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg); }
  .metisMenu > li > ul > li {
    min-height: 30px;
    line-height: 20px; }
    .metisMenu > li > ul > li .nav-thumbnail {
      width: 30px;
      height: 30px;
      line-height: 30px;
      top: 0;
      right: 17px;
      font-size: .7rem;
      font-weight: 600;
      text-align: center; }
    .metisMenu > li > ul > li > a {
      min-height: 30px;
      line-height: 29px;
      font-weight: 400;
      font-size: .85rem;
      padding-left: 2rem; }
    .metisMenu > li > ul > li > ul > li {
      min-height: 24px;
      line-height: 20px; }
      .metisMenu > li > ul > li > ul > li .nav-thumbnail {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: .65rem;
        font-weight: 600;
        right: 20px;
        top: 0; }
      .metisMenu > li > ul > li > ul > li > a {
        min-height: 24px;
        line-height: 24px;
        font-weight: 400;
        font-size: .8rem;
        padding-left: 2.25rem; }
  .metisMenu > li ul > li:first-child {
    padding-top: 7px; }
  .metisMenu > li ul > li:last-child {
    padding-bottom: 7px; }

.metisMenu li > a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.metisMenu li.nav-title {
  clear: both;
  line-height: 36px;
  height: 36px;
  padding-left: 21px;
  padding-top: 0rem;
  margin: 10px 0;
  font-size: .6rem;
  text-transform: uppercase;
  font-weight: 600;
  color: inherit; }
  .metisMenu li.nav-title > .nav-thumbnail {
    font-size: .62rem;
    text-transform: uppercase;
    font-weight: 700; }

/*
Modal and lightboxes;
*/
.modal-dialog .modal-content, .modal-dialog .modal-header {
  border-color: #e1eaea; }

.modal-dialog .modal-header .modal-title {
  font-size: 1rem;
  color: #2c2d3a; }

.note-dialog .modal-dialog {
  z-index: 1050; }

/*
page breadcrumbs
*/
.page-subheader {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  background-color: #f3f8fa;
  border-bottom: 1px solid #e1eaea; }
  .page-subheader h3 {
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 1.25rem;
    line-height: 1.5; }
  .page-subheader .breadcrumb .breadcrumb-item {
    color: inherit; }
    .page-subheader .breadcrumb .breadcrumb-item i.mr-2 {
      margin-right: 0 !important; }
    .page-subheader .breadcrumb .breadcrumb-item.active {
      opacity: .6; }
  .page-subheader .list-item {
    padding: 0px; }
  .page-subheader .list-body {
    padding: 5px 0; }
  .page-subheader .list-thumb {
    line-height: 1.2;
    margin-left: 0px;
    width: auto !important;
    padding: .15rem;
    padding-left: 0px;
    height: auto !important;
    overflow: visible; }
    .page-subheader .list-thumb > i {
      font-size: 24px !important; }

.page-header-space {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative; }
  .page-header-space h4 {
    margin-bottom: 0px; }

.page-header-img {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #0084ff;
  width: 100%; }
  .page-header-img img {
    opacity: .15;
    left: 0;
    width: 100%;
    top: 0; }

/*
paginations style
*/
.page-link {
  border-color: #e1eaea; }

.page-item.active .page-link, .page-link:hover, .page-link:focus {
  background-color: #0084ff;
  border-color: #0084ff;
  color: #fff; }

.page-link:focus {
  box-shadow: none;
  background-color: #1072ce; }

/*
Progress bars
*/
.progress-thumb {
  position: relative;
  display: inline-block; }
  .progress-thumb img {
    border-radius: 100%;
    position: absolute;
    left: 5px;
    top: 5px;
    width: 110px; }

.states {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 10px;
  border: 2px solid #fff;
  width: 12px;
  height: 12px;
  border-radius: 100px; }

.on {
  background-color: #94df4a; }

.away {
  background-color: #f3d768; }

.dnd {
  background-color: #fa625e; }

.offline {
  background-color: #f3f8fa; }

.progress {
  border-radius: 100px;
  -webkit-border-radius: 100px; }

/*
Responsive layouts style
*/
@media (max-width: 991px) {
  .dropdown-menu {
    box-shadow: none; }
  .layout-horizontal .navbar-light .dropdown-menu {
    box-shadow: none;
    padding: 0px;
    border: 0px; }
  .layout-horizontal .navbar-light .row.p-3 {
    padding: 0 !important; }
  .layout-horizontal .navbar-light .navbar-nav {
    padding-top: 1rem; }
    .layout-horizontal .navbar-light .navbar-nav > li {
      padding: 0px; }
  .layout-horizontal .navbar-light .dropdown-submenu .dropdown-menu {
    padding-left: .75rem; }
  .layout-horizontal .navbar-light .dropdown-menu.dropdown-menu-lg {
    width: auto; }
  .layout-horizontal .navbar-light .container {
    padding-left: 15px;
    padding-right: 15px; }
  .layout-horizontal .navbar-light.navbar.navbar-horizontal {
    padding: .5rem 1rem; }
  .layout-horizontal .navbar-light.navbar-light .navbar-toggler {
    border: 0px;
    padding: 0px; }
  .layout-horizontal .navbar-light.navbar.navbar-horizontal.navbar-expand-lg .navbar-nav > li > .nav-link {
    padding: .5rem 1rem;
    text-align: left;
    padding-left: 0px; }
    .layout-horizontal .navbar-light.navbar.navbar-horizontal.navbar-expand-lg .navbar-nav > li > .nav-link > i {
      text-align: left;
      display: inline-block;
      margin-right: .5rem; }
  .layout-horizontal .navbar-light .search-horizontal {
    top: 0;
    right: 15px;
    margin-top: 0px; }
    .layout-horizontal .navbar-light .search-horizontal .form-control {
      position: static; }
    .layout-horizontal .navbar-light .search-horizontal button {
      right: 0; }
  .page-wrapper {
    flex-flow: column;
    align-content: flex-start; }
  .media-chat {
    padding-right: 0px; }
  .page-aside {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 1050;
    background: 0 0 !important;
    position: fixed;
    height: 100%;
    background-color: inherit;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    width: 240px; }
  .page-aside.show {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .aside-footer {
    display: none; }
  .page-header .form-control {
    width: 200px; }
  header.page-header {
    padding-left: 0;
    padding-right: 0; }
    header.page-header .navbar-collapse {
      background-color: #fff; }
      header.page-header .navbar-collapse .form-inline {
        background: #fff;
        max-width: 100%;
        width: 100%;
        border: 0px;
        margin: 0px !important;
        height: 3.45rem; }
        header.page-header .navbar-collapse .form-inline .form-control {
          padding-left: 1rem; }
    header.page-header.darkHeader .navbar-collapse {
      border-top: 0px;
      border-bottom: 0px;
      background-color: #0084ff; }
  .page-header a.nav-link {
    padding: .5rem 1rem; }
    .page-header a.nav-link i.icon-Magnifi-Glass2:before {
      content: "\e772";
      width: 25px; }
    .page-header a.nav-link.collapsed i.icon-Magnifi-Glass2:before {
      content: "\ea6f"; }
  header.page-header .form-inline button {
    text-align: left;
    position: absolute;
    left: calc(100% - 2rem);
    top: 19px;
    line-height: 1; }
  header.page-header .form-inline .form-control {
    height: 100%; }
  .offcanvas-wrapper .offcanvas-collapse.offcanvas-menu {
    width: 96%;
    right: 2%; }
  .sidebar-body .flex-column {
    height: calc(100vh - 3.5rem); } }

@media (max-width: 767px) {
  header.page-header .dropdown {
    position: static; }
  header.page-header .dropdown-menu, header.page-header .dropdown-menu.dropdown-menu-right {
    min-width: 96% !important;
    width: 96% !important;
    left: 2% !important;
    right: auto !important;
    -webkit-transform: none !important;
            transform: none !important; }
  .media-chat p {
    max-width: 100%; }
  .media-chat.media-chat-self {
    padding-right: 0;
    padding-left: 0; }
  .page-subheader .col-md-5 {
    display: none !important; } }

@media (max-width: 576px) {
  .layout-horizontal .navbar-light .container {
    padding-left: 0px;
    padding-right: 0px; }
  .layout-horizontal .navbar-light .search-horizontal button {
    right: -15px; } }

/*
Tables style
*/
.table thead th {
  border-color: #e1eaea;
  border-bottom-width: 1px; }

.table .customUi-checkbox {
  position: relative;
  height: 20px; }
  .table .customUi-checkbox input {
    width: 20px !important;
    height: 20px !important;
    z-index: 1 !important;
    cursor: pointer; }

.table th:hover a.text-muted, .table th:hover a .text-muted, .table td:hover a.text-muted, .table td:hover a .text-muted {
  opacity: 0.85 !important; }

.table td,
.table th {
  border-color: #e1eaea; }

.table.table-primary.table, .table.table-primary > td, .table.table-primary > th {
  background-color: rgba(229, 242, 255, 0.5); }
  .table.table-primary.table thead, .table.table-primary.table th, .table.table-primary > td thead, .table.table-primary > td th, .table.table-primary > th thead, .table.table-primary > th th {
    border-color: rgba(16, 114, 206, 0.05); }

.table.table-primary tbody tr:hover {
  background-color: rgba(0, 132, 255, 0.05); }

.table-hover tbody tr:hover {
  background-color: #f3f8fa; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f8fa; }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.table-dark {
  background-color: #2c2d3a;
  color: #bcbdc5; }

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: rgba(188, 189, 197, 0.25); }

.table-active, .table-active > td, .table-active > th {
  background-color: #e5f2ff; }

.table-success, .table-success > td, .table-success > th {
  background-color: #e5fcd0; }

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #fef8e2; }

.table-info, .table-info > td, .table-info > th {
  background-color: #e7f7fe; }

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #fee5e4; }

.table-teal, .table-teal > td, .table-teal > th {
  background-color: #d9fff8; }

.table-purple, .table-purple > td, .table-purple > th {
  background-color: #f4daff; }

.table-pink, .table-pink > td, .table-pink > th {
  background-color: #fde6f3; }

.table-bordered {
  border-color: #e1eaea; }

table.table-data.dataTable {
  border-collapse: collapse !important; }
  table.table-data.dataTable tr {
    border-collapse: collapse; }
  table.table-data.dataTable thead th {
    border-bottom: 0px; }

table.dataTable {
  border-collapse: collapse !important; }

.dataTables_wrapper select.form-control {
  margin: 0rem .5rem; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  background-color: #0084ff;
  box-shadow: none;
  width: 16px;
  height: 16px;
  display: block;
  border: 0px;
  border-radius: 2px;
  line-height: 19px; }

.table-contacts tr td, .table-contacts tr th {
  vertical-align: middle;
  text-align: left; }

/*
Tabs
*/
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #0084ff;
  color: #e5f2ff; }

.profile-tabs .nav-link {
  background-color: #fff;
  margin-right: .5rem;
  color: #0084ff; }

/*
Timeline
*/
.timeline-sm {
  position: relative; }
  .timeline-sm:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 1rem;
    width: 1px;
    height: 100%;
    background-color: #f3f8fa; }
  .timeline-sm > li {
    padding-bottom: 1rem;
    padding-left: 2.5rem; }
    .timeline-sm > li:last-child {
      padding-bottom: 0px; }

.timeline-alt {
  position: relative; }
  .timeline-alt > li {
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    position: relative; }
    .timeline-alt > li:last-child {
      padding-bottom: 0px; }
  .timeline-alt:before {
    content: "";
    position: absolute;
    left: 10px;
    margin-left: 1px;
    top: 1rem;
    width: 2px;
    height: 100%;
    background-color: #f3f8fa;
    z-index: 0; }
  .timeline-alt .tl-thumb {
    z-index: 2;
    position: absolute;
    top: 5px;
    left: 0;
    display: inline-flex;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    align-items: center !important;
    justify-content: center !important; }
  .timeline-alt .tl-content {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .timeline-alt .tl-content p {
      opacity: .6; }
  .timeline-alt > li:nth-child(even) .tl-content {
    border-radius: 5px;
    background-color: #f3f8fa;
    padding: 1rem;
    border-bottom: 0px; }

/**pace css preloader**/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 3px;
  background: transparent; }

.pace-inactive {
  display: none; }

.pace .pace-progress {
  background-color: #e7f7fe;
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
  overflow: hidden;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05); }

.pace .pace-activity {
  position: fixed;
  top: 0;
  left: 0;
  right: -28px;
  bottom: 0;
  -webkit-animation: pace-theme-mac-osx-motion 500ms linear infinite;
  animation: pace-theme-mac-osx-motion 500ms linear infinite; }

@-webkit-keyframes pace-theme-mac-osx-motion {
  0% {
    -webkit-transform: none;
    transform: none; }
  100% {
    -webkit-transform: translate(-28px, 0);
    transform: translate(-28px, 0); } }

@keyframes pace-theme-mac-osx-motion {
  0% {
    -webkit-transform: none;
            transform: none;
    transform: none; }
  100% {
    -webkit-transform: translate(-28px, 0);
            transform: translate(-28px, 0);
    transform: translate(-28px, 0); } }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.contact {
  display: flex;
  flex-direction: column; }

.requestSuccess .pin h2 {
  margin: 30px 0; }

.requestPending {
  margin-bottom: 20px; }

.requestFailed {
  margin-bottom: 20px; }

.having-trouble .customLink {
  cursor: pointer;
  color: #555555;
  text-decoration: underline; }

.having-trouble .customLink:hover {
  color: #333333;
  text-decoration: underline; }

.clientPage {
  max-width: 450px; }
  .clientPage .faq {
    margin-top: 30px; }
    .clientPage .faq .tillSlip-container {
      height: 100%;
      width: 100px;
      padding: 5px;
      margin: 30px 0 0 0; }
      .clientPage .faq .tillSlip-container .tillSlip {
        width: 250px; }
  .clientPage .myForm {
    max-width: 320px; }
    .clientPage .myForm .label {
      font-size: x-large; }
    .clientPage .myForm input.form-control {
      background-color: white; }
  .clientPage .error {
    color: #a90900;
    padding: 10px;
    max-width: 400px; }

.App {
  /*text-align: center;*/
  display: flex;
  justify-content: center;
  padding: 20px 16px 100px;
}


.having-trouble {
  .customLink {
    cursor: pointer;
    color: #555555;
    text-decoration: underline
  }

  .customLink:hover {
    color: #333333;
    text-decoration: underline
  }
}

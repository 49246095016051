
.clientPage {
  max-width: 450px;

  .faq {

    margin-top: 30px;

    .tillSlip-container {
      height: 100%;
      width: 100px;
      padding: 5px;
      margin: 30px 0 0 0;

      .tillSlip {
        //height: 170px;
        width: 250px;
      }
    }
  }

  .myForm {
    max-width: 320px;

    .label {
      font-size: x-large;
    }

    input.form-control {
      background-color: white;
    }
  }

  .error {
    color: #a90900;
    padding: 10px;
    max-width: 400px;
  }

}


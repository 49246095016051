.requestSuccess {

  .pin {

    h2 {
      margin: 30px 0;
    }
  }

}

.requestPending {
  margin-bottom: 20px;
}

.requestFailed {
  margin-bottom: 20px;
}
